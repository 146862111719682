import React, { Component } from "react";
import "../styles/awards.css";

import Fade from "react-reveal/Fade";

import Award1 from "./awards/award-1";
import Award2 from "./awards/award-2";
import Award3 from "./awards/award-3";
import Award4 from "./awards/award-4";
import Award5 from "./awards/award-5";

class Awards extends Component {
  render() {
    return (
      <div>
        <div className="award-row">
          <Fade>
            {/* <img
            src="https://i.imgur.com/uETcIYX.png"
            alt=""
            className="award-image"
          />
          <img
            src="https://imgur.com/MgVJowV.png"
            alt=""
            className="award-image"
          />
          <img
            src="https://imgur.com/XrgDDi1.png"
            alt=""
            className="award-image"
          />
          <img
            src="https://imgur.com/CCcoiFG.png"
            alt=""
            className="award-image"
          /> */}
            <Award1 className="award1" />
            <a
              rel="me"
              href="https://www.avvo.com/attorneys/60625-il-bayarjargal-sereenen-4578380.html"
              className="avvoProfile"
            >
              <img
                alt="Avvo - Rate your Lawyer. Get Free Legal Advice."
                id="avvo_badge"
                src="//images.avvo.com/avvo/cms/images/amos_assets/microbadge.png"
                className="avvoProfile"
              />
            </a>
            <Award5 className="award5" />
          </Fade>
        </div>
        <div className="award-row">
          <Fade>
            {/* <img
            src="https://i.imgur.com/uETcIYX.png"
            alt=""
            className="award-image"
          />
          <img
            src="https://imgur.com/MgVJowV.png"
            alt=""
            className="award-image"
          />
          <img
            src="https://imgur.com/XrgDDi1.png"
            alt=""
            className="award-image"
          />
          <img
            src="https://imgur.com/CCcoiFG.png"
            alt=""
            className="award-image"
          /> */}
            <Award3 className="award3" />
            <Award4 className="award4" />
            <Award2 className="award2" />
          </Fade>
        </div>
      </div>
    );
  }
}

export default Awards;
