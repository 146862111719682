import React, { Component } from "react";

import "font-awesome/css/font-awesome.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "react-tabs/style/react-tabs.css";

import { Provider } from "react-redux";
import store from "./store";

import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch
} from "react-router-dom";

import Main from "./common/Main";
import Services from "./components/Services";
import Practices from "./components/Practices";
import Login from "./firebase/Login";
import LegalNews from "./common/LegalNews";
import TestimonialsMain from "./common/TestimonialsMain";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <Router>
            <Switch>
              <Route exact path="/" component={Main} />
              <Route path="/services" component={Services} />
              <Route exact path="/practices" component={Practices} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/legalnews" component={LegalNews} />
              <Route
                path="/testimonials/:testimonial"
                component={TestimonialsMain}
              />
            </Switch>
          </Router>
        </div>
      </Provider>
    );
  }
}

export default withRouter(App);
