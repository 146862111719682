import React, { Component } from "react";

import { db, auth } from "./Firebase";

import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import Dashboard from "./Dashboard";

import "../styles/login.scss";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      email: "",
      password: "",
      legalNews: ""
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ user });
      }
    });

    db.ref("-LegalNews/")
      .once("value")
      .then(snapshot => {
        const legalNews = snapshot.val();
        this.setState({
          legalNews: legalNews.currentLegalNews
        });
      });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  login() {
    auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(result => {
        this.setState({
          user: true,
          email: "",
          password: ""
        });
      });
  }

  logout() {
    auth.signOut().then(() => {
      this.setState({
        user: null,
        email: "",
        password: ""
      });
    });
  }

  render() {
    return (
      <div className="login">
        <Navbar />

        {this.state.user ? (
          <div>
            <div className="loginHeader">
              <h1>Dashboard</h1>
              <button onClick={this.logout} className="logoutButton">
                Logout
              </button>
            </div>
            <Dashboard />
          </div>
        ) : (
          <div className="loginSection">
            <div className="loginBox">
              <div className="upperLoginBackground" />
              <div className="loginForm">
                <input
                  type="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  name="email"
                  className="emailInput"
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  name="password"
                  className="pwInput"
                  required
                  minLength="4"
                />
                {this.state.user ? (
                  <button onClick={this.logout} className="loginButton">
                    Logout
                  </button>
                ) : (
                  <button onClick={this.login} className="loginButton">
                    Login
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </div>
    );
  }
}
