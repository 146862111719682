import React, { Component } from "react";

import { Element } from "react-scroll";
import Header from "./Header";
import PracticeAreas from "./PracticeAreas";
import Awards from "./Awards";
import Profile from "./Profile";
import Testimonials from "./Testimonials";
import ContactMe from "./ContactMe";
import HomeBar from "./HomeBar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ParallaxTransition from "./ParallaxTransition";
import Certificates from "./Certificates";

import "../styles/App.css";
import "../styles/header.css";
import "../styles/footer.css";
import "../styles/navbar.css";
import "../styles/profile.css";
import "../styles/testimonials.css";

import "../styles/App.css";

export default class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Element name="header">
          <Header />
        </Element>
        <Element name="practiceAreas">
          <PracticeAreas />
        </Element>
        <Element name="profile">
          <Profile />
        </Element>
        <Awards />
        <ParallaxTransition />
        <Certificates />
        <Element name="testimonials">
          <Testimonials />
        </Element>
        <Element name="contactMe">
          <ContactMe />
        </Element>
        <HomeBar />
        <Footer />
      </React.Fragment>
    );
  }
}
