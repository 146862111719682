import React, { Component } from "react";

import "../../styles/awards.css";

class award_5 extends Component {
  render() {
    return (
      <div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.avvo.com/attorneys/60625-il-bayarjargal-sereenen-4578380.html"
        >
          <svg
            class="avvo-badge"
            id="js-avvo-badge-client-choice-2015"
            className="avvo-badge avvo-badge-style"
            version="1.1"
            viewbox="0 0 273.75 199.375"
            x="0px"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
          >
            <g>
              <g>
                <g>
                  <polygon
                    class="st0"
                    points="81.928,159.03 0.749,159.03 19.764,141.741 0.749,124.233 81.928,124.233"
                  />
                  <polygon
                    class="st1"
                    points="81.944,159.019 31.932,144.413 31.932,109.616 81.944,124.221"
                  />
                </g>
                <g>
                  <polygon
                    class="st2"
                    points="191.967,159.03 273.146,159.03 254.132,141.741 273.146,124.233 191.967,124.233"
                  />
                  <polygon
                    class="st0"
                    points="191.967,159.03 273.146,159.03 254.132,141.741 273.146,124.233 191.967,124.233"
                  />
                  <polygon
                    class="st1"
                    points="191.951,159.019 241.962,144.413 241.962,109.616 191.951,124.221"
                  />
                </g>
              </g>
              <g>
                <rect
                  class="st3"
                  height="191"
                  width="169"
                  x="52.448"
                  y="4.201"
                />
                <path
                  class="st2"
                  d="M217.448,8.201v183h-161v-183H217.448 M225.448,0.201h-8h-161h-8v8v183v8h8h161h8v-8v-183V0.201 L225.448,0.201z"
                />
              </g>
              <rect
                class="st2"
                height="34.841"
                width="210.276"
                x="31.81"
                y="109.594"
              />
              <text class="st3 st10 st12" x="50%" y="133">
                Bayarjargal T. Sereenen
              </text>
              <g>
                <g>
                  <g>
                    <path
                      class="st5"
                      d="M187.036,164.922c0-0.264,0.2-0.428,0.6-0.492l5.377-0.782l2.41-3.874c0.136-0.293,0.311-0.44,0.525-0.44 c0.214,0,0.389,0.147,0.525,0.44l2.41,3.874l5.377,0.782c0.4,0.064,0.6,0.228,0.6,0.492c0,0.158-0.093,0.329-0.278,0.515 l-3.888,3.792l0.921,5.356c0.007,0.05,0.01,0.122,0.01,0.214c0,0.149-0.038,0.276-0.112,0.38 c-0.075,0.103-0.184,0.156-0.327,0.156c-0.136,0-0.279-0.043-0.428-0.129l-4.809-2.527l-4.809,2.527 c-0.158,0.086-0.3,0.129-0.428,0.129c-0.15,0-0.263-0.053-0.337-0.156c-0.075-0.104-0.113-0.231-0.113-0.38 c0-0.043,0.007-0.115,0.022-0.214l0.921-5.356l-3.899-3.792C187.125,165.244,187.036,165.072,187.036,164.922z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st5"
                      d="M157.536,164.922c0-0.264,0.2-0.428,0.6-0.492l5.377-0.782l2.41-3.874c0.136-0.293,0.311-0.44,0.525-0.44 c0.214,0,0.389,0.147,0.525,0.44l2.41,3.874l5.377,0.782c0.4,0.064,0.6,0.228,0.6,0.492c0,0.158-0.093,0.329-0.278,0.515 l-3.888,3.792l0.921,5.356c0.007,0.05,0.01,0.122,0.01,0.214c0,0.149-0.038,0.276-0.112,0.38 c-0.075,0.103-0.184,0.156-0.327,0.156c-0.136,0-0.279-0.043-0.428-0.129l-4.809-2.527l-4.809,2.527 c-0.158,0.086-0.3,0.129-0.428,0.129c-0.15,0-0.263-0.053-0.337-0.156c-0.075-0.104-0.113-0.231-0.113-0.38 c0-0.043,0.007-0.115,0.022-0.214l0.921-5.356l-3.899-3.792C157.625,165.244,157.536,165.072,157.536,164.922z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st5"
                      d="M128.036,164.922c0-0.264,0.2-0.428,0.6-0.492l5.377-0.782l2.41-3.874c0.136-0.293,0.311-0.44,0.525-0.44 c0.214,0,0.389,0.147,0.525,0.44l2.41,3.874l5.377,0.782c0.4,0.064,0.6,0.228,0.6,0.492c0,0.158-0.093,0.329-0.278,0.515 l-3.888,3.792l0.921,5.356c0.007,0.05,0.01,0.122,0.01,0.214c0,0.149-0.038,0.276-0.112,0.38 c-0.075,0.103-0.184,0.156-0.327,0.156c-0.136,0-0.279-0.043-0.428-0.129l-4.809-2.527l-4.809,2.527 c-0.158,0.086-0.3,0.129-0.428,0.129c-0.15,0-0.263-0.053-0.337-0.156c-0.075-0.104-0.113-0.231-0.113-0.38 c0-0.043,0.007-0.115,0.022-0.214l0.921-5.356l-3.899-3.792C128.125,165.244,128.036,165.072,128.036,164.922z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st5"
                      d="M98.536,164.922c0-0.264,0.2-0.428,0.6-0.492l5.377-0.782l2.41-3.874c0.136-0.293,0.311-0.44,0.525-0.44 c0.214,0,0.389,0.147,0.525,0.44l2.41,3.874l5.377,0.782c0.4,0.064,0.6,0.228,0.6,0.492c0,0.158-0.093,0.329-0.278,0.515 l-3.888,3.792l0.921,5.356c0.007,0.05,0.01,0.122,0.01,0.214c0,0.149-0.038,0.276-0.112,0.38 c-0.075,0.103-0.184,0.156-0.327,0.156c-0.136,0-0.279-0.043-0.428-0.129l-4.809-2.527l-4.809,2.527 c-0.158,0.086-0.3,0.129-0.428,0.129c-0.15,0-0.263-0.053-0.338-0.156c-0.075-0.104-0.113-0.231-0.113-0.38 c0-0.043,0.007-0.115,0.022-0.214l0.921-5.356l-3.899-3.792C98.625,165.244,98.536,165.072,98.536,164.922z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st5"
                      d="M69.036,164.922c0-0.264,0.2-0.428,0.6-0.492l5.377-0.782l2.41-3.874c0.136-0.293,0.311-0.44,0.525-0.44 c0.214,0,0.389,0.147,0.525,0.44l2.41,3.874l5.377,0.782c0.4,0.064,0.6,0.228,0.6,0.492c0,0.158-0.093,0.329-0.278,0.515 l-3.888,3.792l0.921,5.356c0.007,0.05,0.01,0.122,0.01,0.214c0,0.149-0.038,0.276-0.112,0.38 c-0.075,0.103-0.184,0.156-0.327,0.156c-0.136,0-0.279-0.043-0.428-0.129l-4.809-2.527l-4.809,2.527 c-0.158,0.086-0.3,0.129-0.428,0.129c-0.15,0-0.263-0.053-0.338-0.156c-0.075-0.104-0.113-0.231-0.113-0.38 c0-0.043,0.007-0.115,0.022-0.214l0.921-5.356l-3.899-3.792C69.125,165.244,69.036,165.072,69.036,164.922z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <path
                  class="st2"
                  d="M157.565,34.975c3.209,0,5.847-2.572,5.847-5.803c0-3.253-2.638-5.825-5.847-5.825 c-3.231,0-5.868,2.572-5.868,5.825C151.696,32.403,154.334,34.975,157.565,34.975 M129.694,34.931 c5.122,0,9.034-6.33,9.298-11.408h-3.781c-0.418,3.275-1.472,5.605-2.858,6.77c-0.11,0.11-0.176,0.044-0.176-0.044l-0.505-6.726 h-3.715l0.989,10.837C128.991,34.799,129.342,34.931,129.694,34.931 M141.981,34.931c5.121,0,9.034-6.33,9.297-11.408h-3.78 c-0.418,3.275-1.473,5.605-2.858,6.77c-0.11,0.11-0.176,0.044-0.176-0.044l-0.505-6.726h-3.715l0.989,10.837 C141.278,34.799,141.629,34.931,141.981,34.931 M121.342,24.556c0.11-0.11,0.219-0.088,0.219,0.044l0.33,4.418h-4.265 C118.902,27.084,120.242,25.501,121.342,24.556 M157.543,31.634c-1.297,0-2.352-1.055-2.352-2.462 c0-1.407,1.056-2.484,2.352-2.484c1.297,0,2.374,1.077,2.374,2.484C159.917,30.579,158.84,31.634,157.543,31.634 M122.331,34.865 h4.088l-1.275-14.419c-0.044-0.593-0.484-0.769-1.231-0.682c-5.056,0.616-11.298,9.012-13.43,15.101h4.11 c0.33-0.879,0.725-1.758,1.165-2.594h6.374L122.331,34.865z"
                />
              </g>
              <text transform="matrix(1 0 0 1 65.8555 66.2344)">
                <tspan class="st6 st7 st8 st9 st12" x="71" y="0">
                  Clients’ Choice
                </tspan>
                <tspan
                  class="st6 st7 st8 st9 st12 js-avvo-badge-year"
                  x="71"
                  y="28"
                >
                  Award 2019
                </tspan>
              </text>
            </g>
          </svg>
        </a>
      </div>
    );
  }
}

export default award_5;
