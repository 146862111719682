import React, { Component } from "react";
import { db } from "../firebase/Firebase";

import Navbar from "./Navbar";
import Footer from "./Footer";

import "../styles/legalNews.scss";

import link from "../images/link-solid.svg";

import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

class LegalNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [
        {
          id: "",
          Active: "",
          currentLegalNews: "",
          date: "",
          title: "",
          url: ""
        }
      ]
    };

    // this.pullLatestNews = this.pullLatestNews.bind(this);
  }

  componentWillMount() {
    db.ref("-LegalNews/")
      .once("value")
      .then(snapshot => {
        const news = Object.keys(snapshot.val()).map(news => {
          return {
            id: news,
            Active: snapshot.val()[news].Active,
            currentLegalNews: snapshot.val()[news].currentLegalNews,
            date: snapshot.val()[news].date,
            title: snapshot.val()[news].title,
            url: snapshot.val()[news].url
          };
        });
        console.log(news);
        this.setState({
          news: news.filter(function(news) {
            return news.Active === true;
          })
        });
      });
  }

  renderTestimonial() {
    return this.state.news.forEach(news => {
      return (
        <p className="legalNews__content--news">{news.currentLegalNews}</p>
      );
    });
  }

  render() {
    // const news = this.state.news.map((news, index) => {
    //   return (
    //     <React.Fragment>
    //       <div className="newsDetail">
    //         <h3 className="newsTitleHeader">
    //           <p className="newsTitle">{news.title}</p>
    //         </h3>
    //         <p className="dateNews">
    //           <strong>{news.date} &nbsp;</strong>
    //         </p>
    //         <a href={news.url} className="newsUrl">
    //           <strong>Source </strong>
    //           <img src={link} className="linkSvg" alt="link" />
    //         </a>
    //       </div>
    //       <p className="legalNews__content--news">{news.currentLegalNews}</p>
    //     </React.Fragment>
    //   );
    // });

    return (
      <div className="legalNews">
        <Navbar />
        <div className="legalNews__content">
          <h1 className="legalNews__content--title">Latest Legal News</h1>
          <div className="legalNews__content--underline" />
          {/* <Carousel
              autoPlay
              infiniteLoop
              showThumbs={false}
              showStatus={false}
              width={"100%"}
            >
              {news}
            </Carousel> */}
          <Slider>
            {this.state.news.map((news, index) => (
              <div className="cardNews">
                <div className="newsDetail">
                  <h3 className="newsTitleHeader">
                    <p className="newsTitle">{news.title}</p>
                  </h3>
                  <p className="dateNews">
                    <strong>{news.date} &nbsp;</strong>
                  </p>
                  <a href={news.url} className="newsUrl">
                    <strong>Source </strong>
                    <img src={link} className="linkSvg" alt="link" />
                  </a>
                </div>
                <p className="legalNews__content--news">
                  {news.currentLegalNews}
                </p>
              </div>
            ))}
          </Slider>
        </div>
        <Footer />
      </div>
    );
  }
}

export default LegalNews;
