import React, { Component } from "react";

import "../../styles/awards.css";

class award_4 extends Component {
  render() {
    return (
      <div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.avvo.com/attorneys/60625-il-bayarjargal-sereenen-4578380.html"
        >
          <svg
            className="avvo-badge avvo-badge-style"
            id="js-avvo-badge-client-reviews"
            version="1.1"
            viewbox="0 0 275.179 203.341"
            x="0px"
            xmlns="http://www.w3.org/2000/svg"
            y="0px"
          >
            <g>
              <g>
                <polygon
                  class="st0"
                  points="82.655,160.901 1.475,160.901 20.49,143.612 1.475,126.104 82.655,126.104"
                />
                <polygon
                  class="st1"
                  points="82.671,160.89 32.659,146.284 32.659,111.486 82.671,126.092"
                />
              </g>
              <g>
                <polygon
                  class="st2"
                  points="192.694,160.901 273.873,160.901 254.858,143.612 273.873,126.104 192.694,126.104"
                />
                <polygon
                  class="st0"
                  points="192.694,160.901 273.873,160.901 254.858,143.612 273.873,126.104 192.694,126.104"
                />
                <polygon
                  class="st1"
                  points="192.677,160.89 242.688,146.284 242.688,111.486 192.677,126.092"
                />
              </g>
              <g>
                <path
                  class="st3"
                  d="M137.675,198.484c-53.21,0-96.5-43.29-96.5-96.5c0-53.21,43.29-96.5,96.5-96.5c53.21,0,96.5,43.29,96.5,96.5 C234.175,155.194,190.885,198.484,137.675,198.484z"
                />
                <path
                  class="st2"
                  d="M137.675,9.484c51.086,0,92.5,41.414,92.5,92.5s-41.414,92.5-92.5,92.5s-92.5-41.414-92.5-92.5 S86.588,9.484,137.675,9.484 M137.675,1.484c-55.416,0-100.5,45.084-100.5,100.5s45.084,100.5,100.5,100.5 s100.5-45.084,100.5-100.5S193.091,1.484,137.675,1.484L137.675,1.484z"
                />
              </g>
              <rect
                class="st2"
                height="34.841"
                width="210.276"
                x="32.536"
                y="111.465"
              />
              <path
                id="SVGID_x5F_123_x5F_"
                class="st4"
                d="M38.116,133.593h194.795"
              />
              <text class="st3 st10 st12" x="50%" y="134">
                Bayarjargal T. Sereenen
              </text>
              <g>
                <path
                  class="st2"
                  d="M158.292,173.384c3.209,0,5.847-2.572,5.847-5.803c0-3.253-2.638-5.825-5.847-5.825 c-3.231,0-5.869,2.572-5.869,5.825C152.423,170.812,155.061,173.384,158.292,173.384 M130.42,173.339 c5.122,0,9.035-6.33,9.298-11.408h-3.781c-0.418,3.275-1.472,5.605-2.858,6.77c-0.11,0.11-0.176,0.044-0.176-0.044l-0.505-6.726 h-3.715l0.989,10.837C129.717,173.208,130.069,173.339,130.42,173.339 M142.708,173.339c5.121,0,9.034-6.33,9.297-11.408h-3.78 c-0.418,3.275-1.473,5.605-2.858,6.77c-0.11,0.11-0.176,0.044-0.176-0.044l-0.506-6.726h-3.715l0.989,10.837 C142.004,173.208,142.356,173.339,142.708,173.339 M122.068,162.964c0.11-0.11,0.219-0.088,0.219,0.044l0.33,4.418h-4.265 C119.628,165.492,120.969,163.91,122.068,162.964 M158.27,170.042c-1.297,0-2.352-1.055-2.352-2.462    c0-1.407,1.056-2.484,2.352-2.484c1.297,0,2.374,1.077,2.374,2.484C160.644,168.987,159.567,170.042,158.27,170.042 M123.057,173.273h4.088l-1.275-14.419c-0.044-0.593-0.484-0.769-1.231-0.682c-5.056,0.616-11.298,9.012-13.43,15.101h4.11 c0.33-0.879,0.725-1.758,1.165-2.594h6.374L123.057,173.273z"
                />
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        class="st5"
                        d="M90.586,66.735c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874c-0.136-0.293-0.311-0.44-0.525-0.44 c0,0,0,0,0,0c-0.214,0-0.389,0.147-0.525,0.44l-2.41,3.874l-5.377,0.782c-0.4,0.064-0.6,0.228-0.6,0.492 c0,0.15,0.089,0.322,0.268,0.515l3.899,3.792l-0.921,5.356c-0.015,0.1-0.022,0.171-0.022,0.214c0,0.149,0.038,0.276,0.113,0.38 c0.075,0.103,0.187,0.156,0.337,0.156c0.129,0,0.271-0.043,0.428-0.129l4.809-2.527l0,0l4.809,2.527 c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156c0.075-0.104,0.112-0.231,0.112-0.38 c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792C90.493,67.064,90.586,66.893,90.586,66.735z"
                      />
                      <path
                        class="st3"
                        d="M88.162,67.392c0-0.192-0.146-0.312-0.437-0.358l-3.914-0.569l-1.754-2.82 c-0.099-0.213-0.226-0.32-0.382-0.32c0,0,0,0,0,0c-0.156,0-0.283,0.107-0.382,0.32l-1.754,2.82l-3.914,0.569 c-0.291,0.047-0.437,0.166-0.437,0.358c0,0.109,0.065,0.234,0.195,0.375l2.838,2.76l-0.671,3.899 c-0.011,0.073-0.016,0.125-0.016,0.156c0,0.109,0.027,0.201,0.082,0.277c0.054,0.075,0.136,0.113,0.246,0.113 c0.094,0,0.197-0.032,0.312-0.094l3.501-1.84l0,0l3.501,1.84c0.109,0.063,0.213,0.094,0.312,0.094 c0.104,0,0.183-0.038,0.238-0.113c0.054-0.075,0.082-0.168,0.082-0.277c0-0.068-0.003-0.12-0.008-0.156l-0.671-3.899l2.83-2.76 C88.094,67.631,88.162,67.506,88.162,67.392z"
                      />
                    </g>
                  </g>
                  <g>
                    <g id="js-avvo-review-star-1-4578380">
                      <path
                        class="st5"
                        d="M81.188,61.587l-2.434,3.874l-5.43,0.782c-0.404,0.064-0.606,0.228-0.606,0.492 c0,0.15,0.09,0.322,0.27,0.515l3.938,3.792l-0.93,5.356c-0.015,0.1-0.022,0.171-0.022,0.214c0,0.149,0.038,0.276,0.114,0.38 c0.075,0.103,0.189,0.156,0.341,0.156c0.13,0,0.274-0.043,0.433-0.129l4.857-2.527V61.148 C81.502,61.148,81.325,61.295,81.188,61.587z"
                      />
                      <path
                        class="st5"
                        d="M86.484,77.018c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156 c0.075-0.104,0.112-0.231,0.112-0.38c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792 c0.185-0.186,0.278-0.357,0.278-0.515c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874 c-0.136-0.293-0.311-0.44-0.525-0.44c0,0,0,0,0,0v13.343l0,0L86.484,77.018z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        class="st5"
                        d="M118.586,66.735c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874 c-0.136-0.293-0.311-0.44-0.525-0.44c0,0,0,0,0,0c-0.214,0-0.389,0.147-0.525,0.44l-2.41,3.874l-5.377,0.782 c-0.4,0.064-0.6,0.228-0.6,0.492c0,0.15,0.089,0.322,0.268,0.515l3.899,3.792l-0.921,5.356c-0.015,0.1-0.022,0.171-0.022,0.214 c0,0.149,0.038,0.276,0.113,0.38c0.075,0.103,0.187,0.156,0.337,0.156c0.129,0,0.271-0.043,0.428-0.129l4.809-2.527l0,0 l4.809,2.527c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156c0.075-0.104,0.112-0.231,0.112-0.38 c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792C118.493,67.064,118.586,66.893,118.586,66.735z"
                      />
                      <path
                        class="st3"
                        d="M116.161,67.392c0-0.192-0.146-0.312-0.437-0.358l-3.914-0.569l-1.754-2.82 c-0.099-0.213-0.226-0.32-0.382-0.32c0,0,0,0,0,0c-0.156,0-0.283,0.107-0.382,0.32l-1.754,2.82l-3.914,0.569 c-0.291,0.047-0.437,0.166-0.437,0.358c0,0.109,0.065,0.234,0.195,0.375l2.838,2.76l-0.671,3.899 c-0.011,0.073-0.016,0.125-0.016,0.156c0,0.109,0.027,0.201,0.082,0.277c0.054,0.075,0.136,0.113,0.246,0.113 c0.094,0,0.197-0.032,0.312-0.094l3.501-1.84l0,0l3.501,1.84c0.109,0.063,0.213,0.094,0.312,0.094 c0.104,0,0.183-0.038,0.238-0.113c0.054-0.075,0.082-0.168,0.082-0.277c0-0.068-0.003-0.12-0.008-0.156l-0.671-3.899l2.83-2.76 C116.094,67.631,116.161,67.506,116.161,67.392z"
                      />
                    </g>
                  </g>
                  <g>
                    <g id="js-avvo-review-star-2-4578380">
                      <path
                        class="st5"
                        d="M109.21,61.587l-2.434,3.874l-5.43,0.782c-0.404,0.064-0.606,0.228-0.606,0.492 c0,0.15,0.09,0.322,0.27,0.515l3.938,3.792l-0.93,5.356c-0.015,0.1-0.022,0.171-0.022,0.214c0,0.149,0.038,0.276,0.114,0.38 c0.075,0.103,0.189,0.156,0.341,0.156c0.13,0,0.274-0.043,0.433-0.129l4.857-2.527V61.148 C109.524,61.148,109.347,61.295,109.21,61.587z"
                      />
                      <path
                        class="st5"
                        d="M114.506,77.018c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156 c0.075-0.104,0.112-0.231,0.112-0.38c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792 c0.185-0.186,0.278-0.357,0.278-0.515c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874 c-0.136-0.293-0.311-0.44-0.525-0.44c0,0,0,0,0,0v13.343l0,0L114.506,77.018z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        class="st5"
                        d="M146.586,66.735c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874 c-0.136-0.293-0.311-0.44-0.525-0.44c0,0,0,0,0,0c-0.214,0-0.389,0.147-0.525,0.44l-2.41,3.874l-5.377,0.782 c-0.4,0.064-0.6,0.228-0.6,0.492c0,0.15,0.089,0.322,0.268,0.515l3.899,3.792l-0.921,5.356c-0.015,0.1-0.022,0.171-0.022,0.214 c0,0.149,0.038,0.276,0.113,0.38c0.075,0.103,0.187,0.156,0.337,0.156c0.129,0,0.271-0.043,0.428-0.129l4.809-2.527l0,0 l4.809,2.527c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156c0.075-0.104,0.112-0.231,0.112-0.38 c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792C146.493,67.064,146.586,66.893,146.586,66.735z"
                      />
                      <path
                        class="st3"
                        d="M144.161,67.392c0-0.192-0.146-0.312-0.437-0.358l-3.914-0.569l-1.754-2.82 c-0.099-0.213-0.226-0.32-0.382-0.32c0,0,0,0,0,0c-0.156,0-0.283,0.107-0.382,0.32l-1.754,2.82l-3.914,0.569 c-0.291,0.047-0.437,0.166-0.437,0.358c0,0.109,0.065,0.234,0.195,0.375l2.838,2.76l-0.671,3.899 c-0.011,0.073-0.016,0.125-0.016,0.156c0,0.109,0.027,0.201,0.082,0.277c0.054,0.075,0.136,0.113,0.246,0.113 c0.094,0,0.197-0.032,0.312-0.094l3.501-1.84l0,0l3.501,1.84c0.109,0.063,0.213,0.094,0.312,0.094 c0.104,0,0.183-0.038,0.238-0.113c0.054-0.075,0.082-0.168,0.082-0.277c0-0.068-0.003-0.12-0.008-0.156l-0.671-3.899l2.83-2.76 C144.094,67.631,144.161,67.506,144.161,67.392z"
                      />
                    </g>
                  </g>
                  <g>
                    <g id="js-avvo-review-star-3-4578380">
                      <path
                        class="st5"
                        d="M137.21,61.587l-2.434,3.874l-5.43,0.782c-0.404,0.064-0.606,0.228-0.606,0.492 c0,0.15,0.09,0.322,0.27,0.515l3.938,3.792l-0.93,5.356c-0.015,0.1-0.022,0.171-0.022,0.214c0,0.149,0.038,0.276,0.114,0.38 c0.075,0.103,0.189,0.156,0.341,0.156c0.13,0,0.274-0.043,0.433-0.129l4.857-2.527V61.148 C137.524,61.148,137.348,61.295,137.21,61.587z"
                      />
                      <path
                        class="st5"
                        d="M142.506,77.018c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156 c0.075-0.104,0.112-0.231,0.112-0.38c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792 c0.185-0.186,0.278-0.357,0.278-0.515c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874 c-0.136-0.293-0.311-0.44-0.525-0.44c0,0,0,0,0,0v13.343l0,0L142.506,77.018z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        class="st5"
                        d="M174.586,66.735c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874 c-0.136-0.293-0.311-0.44-0.525-0.44c0,0,0,0,0,0c-0.214,0-0.389,0.147-0.525,0.44l-2.41,3.874l-5.377,0.782 c-0.4,0.064-0.6,0.228-0.6,0.492c0,0.15,0.089,0.322,0.268,0.515l3.899,3.792l-0.921,5.356c-0.015,0.1-0.022,0.171-0.022,0.214 c0,0.149,0.038,0.276,0.113,0.38c0.075,0.103,0.187,0.156,0.337,0.156c0.129,0,0.271-0.043,0.428-0.129l4.809-2.527l0,0 l4.809,2.527c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156c0.075-0.104,0.112-0.231,0.112-0.38 c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792C174.493,67.064,174.586,66.893,174.586,66.735z"
                      />
                      <path
                        class="st3"
                        d="M172.161,67.392c0-0.192-0.146-0.312-0.437-0.358l-3.914-0.569l-1.754-2.82 c-0.099-0.213-0.226-0.32-0.382-0.32c0,0,0,0,0,0c-0.156,0-0.283,0.107-0.382,0.32l-1.754,2.82l-3.914,0.569 c-0.291,0.047-0.437,0.166-0.437,0.358c0,0.109,0.065,0.234,0.195,0.375l2.838,2.76l-0.671,3.899 c-0.011,0.073-0.016,0.125-0.016,0.156c0,0.109,0.027,0.201,0.082,0.277c0.054,0.075,0.136,0.113,0.246,0.113 c0.094,0,0.197-0.032,0.312-0.094l3.501-1.84l0,0l3.501,1.84c0.109,0.063,0.213,0.094,0.312,0.094 c0.104,0,0.183-0.038,0.238-0.113c0.054-0.075,0.082-0.168,0.082-0.277c0-0.068-0.003-0.12-0.008-0.156l-0.671-3.899l2.83-2.76 C172.094,67.631,172.161,67.506,172.161,67.392z"
                      />
                    </g>
                  </g>
                  <g>
                    <g id="js-avvo-review-star-4-4578380">
                      <path
                        class="st5"
                        d="M165.211,61.587l-2.434,3.874l-5.43,0.782c-0.404,0.064-0.606,0.228-0.606,0.492 c0,0.15,0.09,0.322,0.27,0.515l3.938,3.792l-0.93,5.356c-0.015,0.1-0.022,0.171-0.022,0.214c0,0.149,0.038,0.276,0.114,0.38 c0.075,0.103,0.189,0.156,0.341,0.156c0.13,0,0.274-0.043,0.433-0.129l4.857-2.527V61.148 C165.525,61.148,165.348,61.295,165.211,61.587z"
                      />
                      <path
                        class="st5"
                        d="M170.507,77.018c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156 c0.075-0.104,0.112-0.231,0.112-0.38c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792 c0.185-0.186,0.278-0.357,0.278-0.515c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874 c-0.136-0.293-0.311-0.44-0.525-0.44c0,0,0,0,0,0v13.343l0,0L170.507,77.018z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        class="st5"
                        d="M202.586,66.735c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874 c-0.136-0.293-0.311-0.44-0.525-0.44c0,0,0,0,0,0c-0.214,0-0.389,0.147-0.525,0.44l-2.41,3.874l-5.377,0.782 c-0.4,0.064-0.6,0.228-0.6,0.492c0,0.15,0.089,0.322,0.268,0.515l3.899,3.792l-0.921,5.356c-0.015,0.1-0.022,0.171-0.022,0.214 c0,0.149,0.038,0.276,0.113,0.38c0.075,0.103,0.187,0.156,0.337,0.156c0.129,0,0.271-0.043,0.428-0.129l4.809-2.527l0,0 l4.809,2.527c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156c0.075-0.104,0.112-0.231,0.112-0.38 c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792C202.493,67.064,202.586,66.893,202.586,66.735z"
                      />
                      <path
                        class="st3"
                        d="M200.161,67.392c0-0.192-0.146-0.312-0.437-0.358l-3.914-0.569l-1.754-2.82 c-0.099-0.213-0.226-0.32-0.382-0.32c0,0,0,0,0,0c-0.156,0-0.283,0.107-0.382,0.32l-1.754,2.82l-3.914,0.569 c-0.291,0.047-0.437,0.166-0.437,0.358c0,0.109,0.065,0.234,0.195,0.375l2.838,2.76l-0.671,3.899 c-0.011,0.073-0.016,0.125-0.016,0.156c0,0.109,0.027,0.201,0.082,0.277c0.054,0.075,0.136,0.113,0.246,0.113 c0.094,0,0.197-0.032,0.312-0.094l3.501-1.84l0,0l3.501,1.84c0.109,0.063,0.213,0.094,0.312,0.094 c0.104,0,0.183-0.038,0.238-0.113c0.054-0.075,0.082-0.168,0.082-0.277c0-0.068-0.003-0.12-0.008-0.156l-0.671-3.899l2.83-2.76 C200.094,67.631,200.161,67.506,200.161,67.392z"
                      />
                    </g>
                  </g>
                  <g>
                    <g id="js-avvo-review-star-5-4578380">
                      <path
                        class="st5"
                        d="M193.211,61.587l-2.434,3.874l-5.43,0.782c-0.404,0.064-0.606,0.228-0.606,0.492 c0,0.15,0.09,0.322,0.27,0.515l3.938,3.792l-0.93,5.356c-0.015,0.1-0.022,0.171-0.022,0.214c0,0.149,0.038,0.276,0.114,0.38 c0.075,0.103,0.189,0.156,0.341,0.156c0.13,0,0.274-0.043,0.433-0.129l4.857-2.527V61.148 C193.525,61.148,193.348,61.295,193.211,61.587z"
                      />
                      <path
                        class="st5"
                        d="M198.507,77.018c0.15,0.086,0.293,0.129,0.428,0.129c0.142,0,0.252-0.053,0.327-0.156 c0.075-0.104,0.112-0.231,0.112-0.38c0-0.093-0.003-0.165-0.01-0.214l-0.921-5.356l3.888-3.792 c0.185-0.186,0.278-0.357,0.278-0.515c0-0.264-0.2-0.428-0.6-0.492l-5.377-0.782l-2.41-3.874 c-0.136-0.293-0.311-0.44-0.525-0.44c0,0,0,0,0,0v13.343l0,0L198.507,77.018z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <text
                class="st6 st7 st8 st9 st12"
                transform="matrix(1 0 0 1 98.2742 50.5013)"
                x="40"
              >
                Reviews
              </text>
              <text
                class="st10 st13 st12"
                x="50%"
                y="100"
                id="js-avvo-client-reviews-count-4578380"
              >
                All Reviews
              </text>
            </g>
          </svg>
        </a>
      </div>
    );
  }
}

export default award_4;
