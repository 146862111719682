import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { currentCard } from "../actions/cardActions";
import GreenCard from "./GreenCard";
import Visas from "./Visas";
import Deportation from "./Deportation";
import Card2 from "./Card2";
import Card4 from "./Card4";

import image from "../images/statue-of-liberty-1210001_1920.jpg";
import "../styles/asylum.css";
import Description from "./Description";

class Asylum extends Component {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  onClick(n) {
    if (n.target.id === "1") {
      console.log("test1");
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          links: ["Green Card"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "2") {
      this.payload = {
        card: {
          section: "1",
          subsection: "2",
          links: ["U.S. Citizenship"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "3") {
      this.payload = {
        card: {
          section: "1",
          subsection: "3",
          links: ["Non-Immigrant Visas or Temporary Visas"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "4") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          links: ["Deportation Defense"]
        }
      };
      this.props.currentCard(this.payload.card);
    }
  }

  renderElement() {
    if (this.props.card.subsection === "1") return <GreenCard />;
    if (this.props.card.subsection === "2")
      return (
        <Description
          title="U.S. Citizenship"
          description={
            <div className="post-right_body">
              <div>
                <h2>U.S. Citizenship</h2>
                <br />
                <div>
                  <p>
                    Normally, you must be a legal permanent resident for 5 years
                    to apply for naturalization, except persons who obtained
                    their green cards through marriage. We have been
                    successfully served our clients in petitioning for
                    citizenship, passing the naturalization test, and
                    accompanying them to their interviews. To be eligible for US
                    citizenship, you must:
                  </p>
                  <ul>
                    <li>
                      Be a lawful permanent resident of the United States for 5
                      years, or 3 years if married to a U.S. citizen for a
                      minimum of the 3 years
                    </li>
                    <li>
                      Be physically present in the United States for over 50% of
                      the required residency period
                    </li>
                    <li>Be a person of good moral character</li>
                    <li>Take an oath of loyalty to the United States</li>
                    <li>
                      Be able to speak, read and write simple words and phrases
                      in the English language (although there are certain
                      exceptions to this rule)
                    </li>
                    <li>Pass a test in U.S. history and government</li>
                  </ul>
                  <p>
                    Once you become a US citizen, you may sponsor your spouse,
                    parents, sons and daughters as well as your brothers and
                    sisters for lawful permanent residence in the U.S. Some
                    persons may obtain US citizenship at birth, or while they
                    are minors, through their U.S. citizen parents or
                    grandparents.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsection === "3") return <Visas />;
    if (this.props.card.subsection === "4") return <Deportation />;
    else if (this.props.card.section === "1")
      return (
        <div className="servicesAsylum">
          <div id="1" onClick={this.onClick}>
            <Card2 title="Green Card" id="1" image={image} />
          </div>
          <div id="2" onClick={this.onClick}>
            <Card2
              title="US Citizenship"
              id="2"
              image={image}
              followLink="testimonials/citizenship"
            />
          </div>
          <div id="3" onClick={this.onClick}>
            <Card2
              title="Non-Immigrant Visas or Temporary Visas"
              id="3"
              image={image}
            />
          </div>
          <div id="4" onClick={this.onClick}>
            <Card2 title="Deportation Defense" id="4" image={image} />
          </div>
          <div id="5">
            <Card4
              title="Miscellaneous"
              id="5"
              image={image}
              followLink="testimonials/miscellaneous"
            />
          </div>
        </div>
      );
  }
  render() {
    return this.renderElement();
  }
}

Asylum.propTypes = {
  currentCard: PropTypes.func
};

const mapStatetoProps = state => ({
  auth: state.auth,
  card: state.card,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStatetoProps,
    { currentCard }
  )(Asylum)
);
