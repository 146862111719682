import React, { Component } from "react";
import { Link } from "react-scroll";
import "../styles/homeBar.css";

class HomeBar extends Component {
  render() {
    return (
      <div className="homeBar">
        <Link
          to="header"
          spy={true}
          smooth={true}
          offset={-65}
          duration={500}
          className="homeBar-Link"
        >
          <div className="homeBar-Link__content">
            <div className="homeBar-Link__content-image">
              <svg
                height="48"
                viewBox="0 0 48 48"
                width="48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z"
                  fill="white"
                />
                <path d="M0 0h48v48h-48z" fill="none" />
              </svg>
            </div>
            <div className="homeBar-Link__content-text">BACK TO TOP</div>
          </div>
        </Link>
      </div>
    );
  }
}

export default HomeBar;
