import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { currentCard } from "../actions/cardActions";

import image from "../images/airport-2373727_1920.jpg";
import "../styles/visas.css";

import Card3 from "./Card3";
import Description from "./Description";

class Visas extends Component {
  constructor() {
    super();
    this.state = {
      card: {
        current: "main",
        previous: ""
      }
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick(n) {
    if (n.target.id === "1") {
      this.payload = {
        card: {
          section: "1",
          subsection: "3",
          subsubsection: "1",
          links: ["E2 or Investor Visa"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "2") {
      this.payload = {
        card: {
          section: "1",
          subsection: "3",
          subsubsection: "2",
          links: ["H1B Visa"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "3") {
      this.payload = {
        card: {
          section: "1",
          subsection: "3",
          subsubsection: "3",
          links: ["J1 Visa"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "4") {
      this.payload = {
        card: {
          section: "1",
          subsection: "3",
          subsubsection: "4",
          links: ["Categories of Non-Immigrant Visas"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "5") {
      this.payload = {
        card: {
          section: "1",
          subsection: "3",
          subsubsection: "5",
          links: ["Extension of Stay"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "6") {
      this.payload = {
        card: {
          section: "1",
          subsection: "3",
          subsubsection: "6",
          links: ["Change of Status"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "7") {
      this.payload = {
        card: {
          section: "1",
          subsection: "3",
          subsubsection: "7",
          links: ["F1 Reinstatement"]
        }
      };
      this.props.currentCard(this.payload.card);
    }
  }

  render() {
    if (this.props.card.subsubsection === "1")
      return (
        <Description
          title="E2"
          description={
            <div className="post-right_body">
              <div>
                <h2>E2 or Investor Visa</h2>
                <br />
                <div>
                  <p>
                    The E-2 investor visa is authorized for a national of a
                    country with which the United States has a commercial
                    treaty, who is coming to the United States solely to direct
                    and develop the operations of an enterprise in which he or
                    she has invested, or is actively involved in the process of
                    investing, a substantial amount of capital.
                  </p>
                  <p>
                    If the investor is inside the U.S, we can apply for a change
                    of status, extension of stay, or change of employment for
                    you. If you want to speed up the processing of your
                    petition, you may wish to use USCIS’ premium processing
                    procedure. If the investor is outside of the U.S, we can
                    apply for this category on your behalf directly to a U.S.
                    consular office abroad. The investment involved must place
                    lawfully acquired, owned, and controlled capital at
                    commercial risk with a profit objective, and be subject to
                    loss if the investment fails.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "2")
      return (
        <Description
          title="H1B Visa"
          description={
            <div className="post-right_body">
              <div>
                <h2>H1B Visa</h2>
                <br />
                <div>
                  <p>
                    H1B visas are for foreign-born professionals with employment
                    offer in the U.S. To be eligible for H1B status, you must
                    (1) have a minimum of a four-year university degree or
                    equivalent; (2) be paid at the “prevailing wage”; and (3)
                    the job must typically require a minimum four-year
                    university degree or equivalent. <br />
                    H1B visas are subject to a numerical cap of 65,000 per
                    fiscal year. In addition, 20,000 persons who obtain advanced
                    degrees from universities in the U.S. have their own H1B
                    cap. For cap-subject employers, we start preparing their
                    H-1B petitions as early as January, and submit them so that
                    the USCIS will receive the petitions at the beginning of
                    April. Employees with approved H1B petitions may start
                    employment on October 1st.
                    <br />
                    However, there are special rules for graduates working on
                    OPT (Optional Practical Training) which enable them to
                    continue working for their employers during the spring and
                    summer and automatically change their status to H-1B on
                    October 1st. Certain H1B petitions are exempt from the
                    numerical caps including employment “at universities, at
                    affiliated or related organizations or at non-profit or
                    governmental research institutions.” H1B visas are valid for
                    3 years. However, it can be extended for an additional 3
                    years.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "3")
      return (
        <Description
          title="J1 Visa"
          description={
            <div className="post-right_body">
              <div>
                <h2>J1 Visa</h2>
                <br />
                <div>
                  <p>
                    The J-1 Visa allows those who are approved to participate in
                    an “exchange program” to temporarily remain in the U.S. for
                    the duration of the program. J-1 program categories range
                    from scientists or doctors to students, and can also provide
                    for summer employment.
                    <br />
                    Usually, the J-1 visa holders are expected to return to his
                    home country for two years after completion of his program,
                    in order to provide opportunity for the home country
                    government to utilize the experience and skills they had
                    acquired. However, there is a waiver for the requirement.
                    Spouses and children of J-1 visa holders may get J-2 status.
                    Persons in J-2 status may apply for employment authorization
                    documents.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "4")
      return (
        <Description
          title="Categories of Non-Immigrant Visas"
          description={
            <div className="post-right_body">
              <div>
                <h2>Categories of Non-Immigrant Visas</h2>
                <br />
                <div>
                  <ul>
                    <li>Diplomats and Foreign Government Officials (A)</li>
                    <li>Visitor Visas (B1/B2)</li>
                    <li>Transit Visas (C)</li>
                    <li>Crewmen (D1)</li>
                    <li>Treaty Traders (E1)</li>
                    <li>Treaty Investors (E2)</li>
                    <li>Australian Professionals (E3)</li>
                    <li>Students (F1)</li>
                    <li>
                      Employees of Designated International Institutions (G and
                      NATO)
                    </li>
                    <li>Professional Workers (H1B)</li>
                    <li>Agricultural Workers (H2A)</li>
                    <li>Skilled and Unskilled Workers (H2B)</li>
                    <li>Trainees (H3)</li>
                    <li>Foreign Journalists (I)</li>
                    <li>Exchange Visitors (J1)</li>
                    <li>Fiancées of US citizens (K1)</li>
                    <li>Spouses of US citizens (K3)</li>
                    <li>Intra-Company Transferees (L1)</li>
                    <li>Vocational Students (M1)</li>
                    <li>Persons with Extraordinary Abilities (O)</li>
                    <li>Athletes and Entertainers (P)</li>
                    <li>International Cultural Exchange Visitors (Q)</li>
                    <li>Religious Workers (R)</li>
                    <li>Witnesses and Informants (S)</li>
                    <li>Victims of Human Trafficking (T)</li>
                    <li>Victims of Crime (U)</li>
                  </ul>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "5")
      return (
        <Description
          title="Extension of Stay"
          description={
            <div className="post-right_body">
              <div>
                <h2>Extension of Stay</h2>
                <br />
                <div>
                  <p>
                    The most common type of temporary visas to visit the U.S.
                    are B-1 and B-2 visas and the Visa Waiver Program. Persons
                    may study in USA through F-1, M-1 and J-1 temporary visas.
                    The most common types of temporary visas to work in the U.S.
                    include E, H, L, O and P visas. We serve our clients who
                    want to stay longer in USA for various types of reasons.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "6")
      return (
        <Description
          title="CHANGE OF STATUS"
          description={
            <div className="post-right_body">
              <div>
                <h2>Change of Status</h2>
                <br />
                <div>
                  <p>
                    Vast majority of our clients are in need to change their
                    visa status from B1/B2 visa to F1/student visa status. Also,
                    some of our clients want to change their status to
                    E2/Investor status. We have changed hundreds of our clients
                    visa status successfully.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "7")
      return (
        <Description
          title="F1 Reinstatement"
          description={
            <div className="post-right_body">
              <div>
                <h2>F1 Reinstatement</h2>
                <br />
                <div>
                  <p>
                    Some of our clients turn to us, when their F1/Student visas
                    become out of status for variety of reasons. We have
                    successfully reinstated for hundreds of our clients.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsection === "3") {
      return (
        <div className="servicesVisas">
          <div className="cardsVisas">
            <div className="cardVisas" id="1" onClick={this.onClick}>
              <Card3
                title="E2 or Investor Visa"
                id="1"
                image={image}
                followLink="testimonials/E2"
              />
            </div>
            <div className="cardVisas" id="2" onClick={this.onClick}>
              <Card3
                title="H1B Visa"
                id="2"
                image={image}
                followLink="testimonials/H1B"
              />
            </div>
            <div className="cardVisas" id="3" onClick={this.onClick}>
              <Card3
                title="J1 Visa"
                id="3"
                image={image}
                followLink="testimonials/J1"
              />
            </div>
            <div className="cardVisas" id="4" onClick={this.onClick}>
              <Card3
                title="Categories of Non-Immigrant Visas"
                id="4"
                image={image}
              />
            </div>
            <div className="cardVisas" id="5" onClick={this.onClick}>
              <Card3
                title="Extension of Stay"
                id="5"
                image={image}
                followLink="testimonials/Extension"
              />
            </div>
            <div className="cardVisas" id="6" onClick={this.onClick}>
              <Card3
                title="Change of Status"
                id="6"
                image={image}
                followLink="testimonials/ChangeofStatus"
              />
            </div>
            <div className="cardVisas" id="7" onClick={this.onClick}>
              <Card3
                title="F1 Reinstatement"
                id="7"
                image={image}
                followLink="testimonials/F1Reinstatement"
              />
            </div>
          </div>
        </div>
      );
    } else if (this.props.card.cardName === "family") {
      return <div>family</div>;
    }
  }
}

Visas.propTypes = {
  currentCard: PropTypes.func
};

const mapStatetoProps = state => ({
  auth: state.auth,
  card: state.card,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStatetoProps,
    { currentCard }
  )(Visas)
);
