import React, { Component } from "react";
import {
  CollapsibleComponent,
  CollapsibleHead,
  CollapsibleContent
} from "react-collapsible-component";

import { db } from "./Firebase";

import "../styles/firebaseCards.scss";

class FirebaseCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalNews: this.props.legalNews.legalNews,
      loading: true,
      expanded: false,
      title: this.props.legalNews.title,
      url: this.props.legalNews.url
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateTestimonial = this.updateTestimonial.bind(this);
    this.deleteTestimonial = this.deleteTestimonial.bind(this);
    this.updateTestimonialActive = this.updateTestimonialActive.bind(this);
    this.expand = this.expand.bind(this);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  updateTestimonial() {
    db.ref()
      .child("/-LegalNews/" + this.props.legalNews.id)
      .update({
        currentLegalNews: this.state.legalNews,
        title: this.state.title,
        url: this.state.url
      });
    this.forceUpdate();
  }
  updateTestimonialActive() {
    var status = this.props.legalNews.Active;
    db.ref()
      .child("/-LegalNews/" + this.props.legalNews.id)
      .update({
        Active: !status
      });
    this.props.legalNews.Active = !status;
  }
  deleteTestimonial() {
    db.ref()
      .child("/-LegalNews/" + this.props.legalNews.id)
      .remove();
  }
  expand() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    var checked = this.props.legalNews.Active ? "checked" : null;
    return (
      <div className="fbCard">
        <CollapsibleComponent onClick={this.expand}>
          <div onClick={this.expand}>
            <CollapsibleHead
              isExpanded={this.state.expanded}
              className="additionalClassForHead"
            >
              {this.props.legalNews.title} ({this.props.legalNews.date})
            </CollapsibleHead>
          </div>
          <CollapsibleContent
            isExpanded={this.state.expanded}
            className="additionalClassForContent"
          >
            <div className="updateBox">
              <div>
                <textarea
                  type="text"
                  placeholder="Add Title..."
                  value={this.state.title}
                  onChange={this.handleChange}
                  name="title"
                  className="titleInput"
                />
                <textarea
                  type="text"
                  placeholder="Add URL..."
                  value={this.state.url}
                  onChange={this.handleChange}
                  name="url"
                  className="urlInput"
                />
              </div>
              <textarea
                type="text"
                placeholder="Add Legal News..."
                value={this.state.legalNews}
                onChange={this.handleChange}
                name="legalNews"
                className="dropdownInput"
              />
              <div className="testimonialButtons">
                <button
                  onClick={this.updateTestimonial}
                  className="testimonialButtons__submitButton"
                >
                  UPDATE
                </button>
                <button
                  onClick={this.deleteTestimonial}
                  className="testimonialButtons__submitButton"
                >
                  Delete
                </button>
                <div className="checkboxGroup">
                  <input
                    onClick={this.updateTestimonialActive}
                    className="testimonialButtons__checkBox"
                    type="checkbox"
                    checked={checked}
                  />
                  {this.props.legalNews.Active ? (
                    <strong>Displayed on Front page</strong>
                  ) : (
                    <strong>Not being Displayed on Front Page</strong>
                  )}
                </div>
              </div>
            </div>
          </CollapsibleContent>
        </CollapsibleComponent>
      </div>
    );
  }
}

export default FirebaseCards;
