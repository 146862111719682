import { GET_CARDS, DEFAULT_CARDS } from "./types";

export const currentCard = cardData => dispatch => {
  if (cardData.links) {
    console.log("there is a nav data");
    dispatch({
      type: GET_CARDS,
      payLoad: cardData
    });
  } else {
    console.log("default_Cards");
    dispatch({
      type: DEFAULT_CARDS,
      payLoad: cardData
    });
  }
};
