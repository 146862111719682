import React, { Component } from "react";
import "../styles/scss/Card.scss";
import title_scale from "../images/title_scale.png";

export default class Card2 extends Component {
  render() {
    return (
      <div className="blog-slider">
        <div className="blog-slider__wrp swiper-wrapper">
          <div className="blog-slider__item swiper-slide">
            <div className="blog-slider__img">
              <img src={this.props.image} alt="" />
            </div>
            <div className="blog-slider__content">
              <span className="blog-slider__code">
                <img
                  className="alignnone size-full wp-image-3559"
                  src={title_scale}
                  alt=""
                  width="31"
                  height="25"
                />
              </span>
              <div className="blog-slider__title">{this.props.title}</div>
              <div className="blog-slider__text">
                Click "READ MORE" to view more information about{" "}
                {this.props.title}.
              </div>
              <a
                href="javascript:void(0)"
                className="blog-slider__button"
                id={this.props.id}
              >
                READ MORE
              </a>
              {this.props.followLink ? (
                <a
                href={this.props.followLink}
                className="blog-slider__button1"
              >
                TESTIMONIALS
              </a>
                ) : (
                  null

              )}
              
            </div>
          </div>
        </div>
        <div className="blog-slider__pagination" />
      </div>
    );
  }
}
