import React, { Component } from "react";
import { db } from "./Firebase";

import FirebaseCards from "./FirebaseCards";

class AddTestimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Testimonials: "",
      TestimonialList: [],
      loading: true,
      selectedCat: "DUI"
    };
    this.updateTestimonials = this.updateTestimonials.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setState = this.setState.bind(this);
  }
  componentDidMount() {
    db.ref("Testimonials/").on("value", snapshot => {
      var testimonial = [];
      testimonial = Object.keys(snapshot.val()).map(testimonial => {
        return {
          id: testimonial,
          TestimonialText: snapshot.val()[testimonial].TestimonialText,
          Active: snapshot.val()[testimonial].Active,
          date: snapshot.val()[testimonial].date,
          Category: snapshot.val()[testimonial].Category
        };
      });
      this.setState({
        TestimonialList: testimonial
      });
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  updateTestimonials() {
    var date = new Date();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    var exactDate = month + "/" + day + "/" + year;
    db.ref()
      .child("/Testimonials")
      .push({
        TestimonialText: this.state.Testimonials,
        date: exactDate,
        Active: false,
        Category: this.state.selectedCat
      });
    this.setState({
      Testimonials: ""
    });
  }
  render() {
    const testimonials =
      this.state.TestimonialList.length === 0 ? (
        <h1>loading</h1>
      ) : (
        this.state.TestimonialList.map((Testimonial, index) => {
          return <FirebaseCards Testimonial={Testimonial} />;
        })
      );
    return (
      <div className="dashboard__content">
        <div style={{ display: "flex", "align-items": "center" }}>
          <h3>
            <strong>Add Testimonials </strong>
          </h3>
          <select
            className="legalNewsDropdown"
            onChange={this.handleChange}
            name="selectedCat"
          >
            <option value="dui" name="selectedCat">
              DUI
            </option>
            <option value="criminallaw" name="selectedCat">
              Criminal Law
            </option>
            <option value="divorce" name="selectedCat">
              Divorce
            </option>
            <option value="realestate" name="selectedCat">
              Real Estate
            </option>
            <option value="citizenship" name="selectedCat">
              Citizenship
            </option>
            <option value="investor" name="selectedCat">
              Investor
            </option>
            <option value="asylum" name="selectedCat">
              Asylum
            </option>
            <option value="uvise" name="selectedCat">
              U Visa
            </option>
            <option value="tvisa" name="selectedCat">
              T Visa
            </option>
            <option value="immediaterelatives" name="selectedCat">
              Immediate Relatives
            </option>
            <option value="parent" name="selectedCat">
              Parents
            </option>
            <option value="marriedchildren" name="selectedCat">
              Married Children
            </option>
            <option value="brothersandsisters" name="selectedCat">
              Brothers and Sisters
            </option>
            <option value="unmarriedchildren" name="selectedCat">
              Unmarried Children
            </option>
            <option value="samesex" name="selectedCat">
              Same Sex Couples
            </option>
            <option value="marriage" name="selectedCat">
              Marriage
            </option>
            <option value="751" name="selectedCat">
              751
            </option>
            <option value="adoptions" name="selectedCat">
              Adoptions
            </option>
            <option value="601a" name="selectedCat">
              601a
            </option>
            <option value="Persons of Extraordinary Ability" name="selectedCat">
              Persons of Extraordinary Ability
            </option>
            <option value="professors" name="selectedCat">
              Professors
            </option>
            <option value="nationalinterest" name="selectedCat">
              National Interest
            </option>
            <option value="religousworkers" name="selectedCat">
              Religous Workers
            </option>
            <option value="perm" name="selectedCat">
              Perm
            </option>
            <option value="E2" name="selectedCat">
              E2
            </option>
            <option value="H1B" name="selectedCat">
              H1B
            </option>
            <option value="J1" name="selectedCat">
              J1
            </option>
            <option value="Extension" name="selectedCat">
              Extension
            </option>
            <option value="ChangeofStatus" name="selectedCat">
              Change of Status
            </option>
            <option value="F1Reinstatement" name="selectedCat">
              F1 Reinstatement
            </option>
            <option value="Miscellaneous" name="selectedCat">
              Miscellaneoust
            </option>
            <option value="adjustment" name="selectedCat">
              Adjustment
            </option>
            <option value="cancellation" name="selectedCat">
              Cancellation
            </option>
            <option value="212C" name="selectedCat">
              212C
            </option>
            <option value="212H" name="selectedCat">
              212H
            </option>
            <option value="moralturpitude" name="selectedCat">
              Moral Turpitude
            </option>
            <option value="aggravatedfelony" name="selectedCat">
              Aggravated Felony
            </option>
            <option value="212I" name="selectedCat">
              212I
            </option>
          </select>
        </div>
        <textarea
          type="text"
          placeholder="Add Legal News..."
          value={this.state.Testimonials}
          onChange={this.handleChange}
          name="Testimonials"
          className="legalNewsInput"
        />
        <button onClick={this.updateTestimonials} className="submitButton">
          Submit
        </button>
        {testimonials}
      </div>
    );
  }
}

export default AddTestimonials;
