import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { db } from "./Firebase";

import AddLegalNews from "./AddLegalNews";
import AddTestimonials from "./AddTestimonials";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalNews: ""
    };
    this.updateLegalNews = this.updateLegalNews.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    db.ref("-LegalNews/")
      .once("value")
      .then(snapshot => {
        const legalNews = snapshot.val();
        this.setState({
          legalNews: legalNews.currentLegalNews
        });
      });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  updateLegalNews() {
    db.ref()
      .child("/-LegalNews")
      .push({
        currentLegalNews: this.state.legalNews
      });
  }
  render() {
    return (
      <div className="dashboard">
        <Tabs>
          <TabList>
            <Tab>Add LegalNews</Tab>
            <Tab>Add Testimonials</Tab>
          </TabList>

          <TabPanel>
            <AddLegalNews />
          </TabPanel>
          <TabPanel>
            <AddTestimonials />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default Dashboard;
