import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { currentCard } from "../actions/cardActions";

import Asylum from "./Asylum";
import Description from "./Description";
import Card2 from "./Card2";
import Navigation from "./Navigation";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

import image from "../images/silhouette-1082129_1920.jpg";
import "../styles/services.css";

class Practices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: {
        section: "main"
      },
      links: ["Home"]
    };

    this.onClick = this.onClick.bind(this);
    this.back = this.back.bind(this);
  }

  componentWillUnmount() {
    this.payload = {
      card: {
        section: "main",
        remove: this.props.card.links.slice(0, 1)
      }
    };
    this.props.currentCard(this.payload.card);
  }

  onClick(n) {
    if (n.target.id === "2") {
      this.payload = {
        card: {
          section: "2",
          links: ["DUI"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "3") {
      this.payload = {
        card: {
          section: "3",
          links: ["Criminal Defense"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "4") {
      this.payload = {
        card: {
          section: "4",
          links: ["Family Law"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "5") {
      this.payload = {
        card: {
          section: "5",
          links: ["Real Estate"]
        }
      };
      this.props.currentCard(this.payload.card);
    }
  }

  back() {
    if (this.props.card.subsubsubsection) {
      this.payload = {
        card: {
          section: this.props.card.section,
          subsection: this.props.card.subsection,
          subsubsection: this.props.card.subsubsection,
          subsubsubsection: ""
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (this.props.card.subsubsection) {
      this.payload = {
        card: {
          section: this.props.card.section,
          subsection: this.props.card.subsection,
          subsubsection: ""
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (this.props.card.subsection) {
      this.payload = {
        card: {
          section: this.props.card.section,
          subsection: ""
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (this.props.card.section) {
      if (this.props.card.section !== "main") {
        this.payload = {
          card: {
            section: "main"
          }
        };
        this.props.currentCard(this.payload.card);
      } else {
        console.log("end");
      }
    }
  }

  renderElement() {
    window.scrollTo(0, 0);
    if (this.props.card.section === "main")
      return (
        <React.Fragment>
          <div className="servicesCard" id="2" onClick={this.onClick}>
            <Card2
              title="DUI"
              id="2"
              image={image}
              followLink="testimonials/dui"
            />
          </div>
          <div className="servicesCard" id="3" onClick={this.onClick}>
            <Card2
              title="Criminal Defense"
              id="3"
              image={image}
              followLink="testimonials/criminallaw"
            />
          </div>
          <div className="servicesCard" id="4" onClick={this.onClick}>
            <Card2
              title="Family Law"
              id="4"
              image={image}
              followLink="testimonials/divorce"
            />
          </div>
          <div className="servicesCard" id="5" onClick={this.onClick}>
            <Card2
              title="Real Estate"
              id="5"
              image={image}
              followLink="testimonials/realestate"
            />
          </div>
        </React.Fragment>
      );
    else if (this.props.card.section === "2")
      return (
        <Description
          title="DUI"
          description={
            <div class="post-right_body">
              <div>
                <h3>Driving Under The Influence (DUI)</h3>
                <br />
                <p>
                  DUI is what is commonly called “drunk driving,” it refers to
                  operating a motor vehicle while one’s blood alcohol content is
                  above the legal limit set by statute, which supposedly is the
                  level at which a person cannot drive safely. State statutes
                  vary as to what that level is, but it ranges from .08 to .10.
                  Driving on private property such as a parking lot is no
                  defense, but sitting in a non-moving vehicle without the
                  ignition on probably is (sometimes resulting in a charge of
                  “drunk in and about a vehicle”). This is a misdemeanor and is
                  variously referred to as DUI, driving while intoxicated (DWI),
                  drunk driving, or a “deuce”.
                </p>
                <h3>TRAFFIC TICKETS (DUI)</h3>
                <br />
                <p>
                  A traffic citation is a summons issued by a law enforcement
                  officer to a person violating a traffic law. A traffic
                  citation is commonly known as a traffic ticket. The citation
                  is a piece of paper that describes one or more violations that
                  the person may have committed. On receiving a traffic
                  citation, the accused person should appear before a court to
                  pay an fine associated with the charge or contest the charge.
                </p>
                <br />
                <br />
                <p>
                  A traffic citation may include one or more violation,
                  depending on the violations made. Traffic citation may be
                  issued for violations such as illegal lane change, speeding,
                  lack of insurance, no fastened seat belt, or a broken tail
                  light. You received five (5) Violations. To document the five
                  (5) Violations, you will likely receive three (3) Traffic
                  Citations. Two (2) Violations per Traffic Citation, plus one
                  (1) Violation equals three (3) citations. If you received a
                  white slip with computer printing during a Traffic Stop, all
                  the Violations will likely be listed thereon.
                </p>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.section === "3")
      return (
        <Description
          title="Criminal Law"
          description={
            <div class="post-right_body">
              <h2>Criminal Law</h2>
              <br />
              <div>
                <p>
                  A body of rules and statutes that defines conduct prohibited
                  by the government because it threatens and harms public safety
                  andwelfare and that establishes punishment to be imposed for
                  the commission of such acts.
                </p>
                <br />
                <p>
                  The term criminal law generally refers to substantive criminal
                  laws. Substantive criminal laws define crimes and may
                  establishpunishments. In contrast, Criminal Procedure
                  describes the process through which the criminal laws are
                  enforced. For example,the law prohibiting murder is a
                  substantive criminal law. The manner in which government
                  enforces this substantive law—throughthe gathering of evidence
                  and prosecution—is generally considered a procedural matter.
                </p>
                <p>
                  Crimes are usually categorized as felonies or misdemeanors
                  based on their nature and the maximum punishment that can
                  beimposed. A felony involves serious misconduct that is
                  punishable by death or by imprisonment for more than one year.
                  Most statecriminal laws subdivide felonies into different
                  classes with varying degrees of punishment. Crimes that do not
                  amount to feloniesare misdemeanors or violations. A
                  misdemeanor is misconduct for which the law prescribes
                  punishment of no more than one yearin prison. Lesser offenses,
                  such as traffic and parking infractions, are often called
                  violations and are considered a part of criminallaw.
                </p>
                <h4>Expungement</h4>
                <p>
                  A criminal record can create difficult downstream consequences
                  whether you (or your child) was arrested or convicted (or
                  both). For example, employers and landlords commonly ask job
                  applicants and apartment seekers whether they have ever been
                  convicted of (or perhaps even arrested for) a criminal
                  offense. Employers might not hire — and landlords might not
                  rent to — people who answer “yes” to these questions. The good
                  news is that, in some cases, you may be able to get an arrest
                  or conviction expunged from your record. Read on to learn more
                  about the expungement process.
                </p>
                <h3>What Is Expungement?</h3>
                <br />
                <p>
                  A traffic citation is a summons issued by a law enforcement
                  officer to a person violating a traffic law. A traffic
                  citation is commonly known as a traffic ticket. The citation
                  is a piece of paper that describes one or more violations that
                  the person may have committed. On receiving a traffic
                  citation, the accused person should appear before a court to
                  pay an fine associated with the charge or contest the charge.
                </p>
                <br />
                <br />
                <p>
                  A traffic citation may include one or more violation,
                  depending on the violations made. Traffic citation may be
                  issued for violations such as illegal lane change, speeding,
                  lack of insurance, no fastened seat belt, or a broken tail
                  light. You received five (5) Violations. To document the five
                  (5) Violations, you will likely receive three (3) Traffic
                  Citations. Two (2) Violations per Traffic Citation, plus one
                  (1) Violation equals three (3) citations. If you received a
                  white slip with computer printing during a Traffic Stop, all
                  the Violations will likely be listed thereon.
                </p>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.section === "4")
      return (
        <Description
          title="FAMILY LAW"
          description={
            <div class="post-right_body">
              <p>
                <span>W</span>e provide legal services people who need child
                custody and dissolution of marriage cases. We strive for cost
                effective and and quality legal services for our clients. No
                matter where the marriage was registered and no matter your
                current spouse is located, you are eligible to start divorce
                proceedings in Illinois, as long as you have resided in state of
                Illinois for last 90 days.
              </p>
            </div>
          }
        />
      );
    else if (this.props.card.section === "5")
      return (
        <Description
          title="Real Estate"
          description={
            <div class="post-right_body">
              <p>
                <span>W</span>e represent our clients in real estate closings by
                providing competent legal services for both buyer's and seller's
                side of the real estate transactions. We have developed a solid
                professional networks among fellow real estate attorneys,
                mortgage brokers, and realtors in greater Chicago area to make
                real estate transactions to be as smooth as possible for our
                clients.
              </p>
            </div>
          }
        />
      );
  }

  renderButton() {
    if (this.props.card.section !== "main")
      return (
        <div className="backArrow" onClick={this.back}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-8 mr-4 icon-arrow-thin-left-circle"
          >
            <circle cx="12" cy="12" r="10" class="primary" />
            <path
              class="secondary"
              d="M9.41 11H17a1 1 0 0 1 0 2H9.41l2.3 2.3a1 1 0 1 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.42 1.4L9.4 11z"
            />
          </svg>
        </div>
      );
    else
      return (
        <div className="backArrowHidden" onClick={this.back}>
          <p />
        </div>
      );
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="servicesMain">
          {/* <ul className="navigation">{this.listItems}</ul> */}
          <Navigation entries={this.props.card.links} />
          {this.renderElement()}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

Practices.propTypes = {
  currentCard: PropTypes.func
};

const mapStatetoProps = state => ({
  auth: state.auth,
  card: state.card,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStatetoProps,
    { currentCard }
  )(Practices)
);
