import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { currentCard } from "../actions/cardActions";

import image from "../images/road-908176_1920.jpg";
import "../styles/greencard.css";

import Family from "./Family";
import Employment from "./Employment";
import Card3 from "./Card3";
import Description from "./Description";

class GreenCard extends Component {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  onClick(n) {
    if (n.target.id === "1") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          links: ["Family Based"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "2") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "2",
          links: ["Employment Based"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "3") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "3",
          links: ["Investor"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "4") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "4",
          links: ["Asylum"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "5") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "5",
          links: ["U Visa"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "6") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "6",
          links: ["T Visa"]
        }
      };
      this.props.currentCard(this.payload.card);
    }
  }

  render() {
    if (this.props.card.subsubsection === "1") return <Family />;
    if (this.props.card.subsubsection === "2") return <Employment />;
    if (this.props.card.subsubsection === "3")
      return (
        <Description
          title="Investor"
          description={
            <div className="post-right_body">
              <div>
                <h2>Investor Based Green Card</h2>
                <br />
                <div>
                  <p>
                    EB5 investor green cards are granted to those who invest $1
                    million in a new commercial enterprise and create 10 jobs
                    for U.S. workers. However, if the enterprise is located in
                    either a rural or a high-employment area, the amount of
                    funds that needs to be invested is reduced to $500,000.
                  </p>
                  <p>
                    Over 90% of all EB5 investors do not start their own
                    businesses. Instead, they invest $500,000 in one of over 680
                    Regional Centers. 10,000 immigrant visas per year are
                    available to qualified individuals seeking EB5 investor
                    green cards. A Regional Center is s an entity, organization
                    or agency that has been approved as such by the Service;
                    Focuses on a specific geographic area within the United
                    States; and, Seeks to promote economic growth through
                    increased export sales, improved regional productivity,
                    creation of new jobs, and increased domestic capital
                    investment.
                  </p>
                  <p>
                    EB5 Investors must demonstrate that a “qualified investment”
                    is being made in a new commercial enterprise located within
                    an approved Regional Center; and, show, using reasonable
                    methodologies, that 10 or more jobs are actually created
                    either directly or indirectly by the new commercial
                    enterprise through revenues generated from increased
                    exports, improved regional productivity, job creation, or
                    increased domestic capital investment resulting from the
                    pilot program.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsection === "4")
      return (
        <Description
          title="Asylum"
          description={
            <div class="post-right_body">
              <div>
                <h2>Asylum</h2>
                <br />
                <div>
                  <p>
                  You may avoid deportation through a successful asylum petition. You qualify for asylum in the US if you have been persecuted or have a “well-founded fear of persecution” in your country based on (1) political opinion, (2) religion, (3) race, (4) nationality, or (5) membership of a particular a social group. If you are outside the US, you may apply for refugee status based on the above criteria. Your fear of persecution must be either by the government of your country or by a group that the government is unable or unwilling to control.
                  If you are able to establish past persecution, a presumption arises that you have established a well-founded fear of persecution. The burden of proof shifts to the government to demonstrate that circumstances have changed and that you no longer have a well-founded fear of persecution or that you could avoid persecution by relocating in another part of your country and that it would be reasonable for you to do so.
                  If you are in lawful immigration status, you can submit an application for asylum directly with the appropriate USCIS Service Center. Should your application be denied, you will remain in lawful status. However, if you are not in lawful status, should your application not be approved by the USCIS, you will be placed in removal proceedings. If you are in removal proceedings before an immigration judge, in addition to applying for asylum, you may be eligible to apply for withholding of removal and for relief under the Convention Against Torture.
                  If the government can demonstrate that you have firmly resettled in a 3rd country, you are ineligible for asylum, withholding of removal and Convention Against Torture. Generally, you must apply for asylum within 1 year, after arriving in the US. However, there are several exceptions to this rule.
                  Once your asylum application has been pending for over 150 days, you may apply for a work permit. If you are granted asylum, and your spouse and/or children are outside the US, then you may bring them to the US as asylees. One year after you are granted asylum, you may apply for a green card.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsection === "5")
      return (
        <Description
          title="U Visa"
          description={
            <div>
              <div className="post-right_body">
                <h2>U Visas For Crime Victims</h2>
                <br />
                <div>
                  <p>
                    Victims of certain crimes and those who have suffered substantial physical and/or mental abuse may be eligible to apply for a U visa, if you are willing to assist a law enforcement agency in investigating the crime. After being in the US for 3 years with U visa status, you may apply for a green card. U visa status allows you to live and work in the US. Once you obtain a U visa, you can apply for lawful status for your spouse and children. You can also apply for a Work Permit. In order qualify for this visa status, you must have suffered substantial physical or mental abuse due to a criminal activity in one of the following categories: rape, torture, trafficking, incest,domestic violence, sexual assault, abusive sexual contact, prostitution, sexual exploitation, female genital mutilation, hostage situations, peonage, false imprisonment, involuntary servitude, slave trade, kidnapping, abduction, unlawful criminal restraint, blackmail, extortion, manslaughter, murder, felonious assault, witness tampering, obstruction of justice, perjury or attempt, conspiracy, or solicitation to commit any of the above mentioned crimes.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsection === "6")
      return (
        <Description
          title="T Visa"
          description={
            <div className="post-right_body">
              <div>
                <h2>T Visa</h2>
                <br />
                <div>
                  <p>
                    In October 2000, Congress created the “T” non-immigrant
                    status by passing the Victims of Trafficking and Violence
                    Protection Act (VTVPA). The legislation strengthens the
                    ability of law enforcement agencies to investigate and
                    prosecute human trafficking, and also to offer protection to
                    victims.
                  </p>
                  <p>
                    Human trafficking, also known as trafficking in persons, is
                    a form of modern-day slavery in which traffickers lure
                    individuals with false promises of employment and a better
                    life. Traffickers often take advantage of poor, unemployed
                    individuals who lack access to social services. The T
                    Nonimmigrant Status (T visa) is a set aside for those who
                    are or have been victims of human trafficking, protects
                    victims of human trafficking and allows victims to remain in
                    the United States to assist in an investigation or
                    prosecution of human trafficking.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsection === "1") {
      return (
        <div className="servicesGC">
          <div className="cardsGC">
            <div className="cardGC" id="1" onClick={this.onClick}>
              <Card3 title="Family Based" id="1" image={image} />
            </div>
            <div className="cardGC" id="2" onClick={this.onClick}>
              <Card3 title="Employment Based" id="2" image={image} />
            </div>
            <div className="cardGC" id="3" onClick={this.onClick}>
              <Card3 title="Investor" id="3" image={image} followLink = "testimonials/investor"/>
            </div>
            <div className="cardGC" id="4" onClick={this.onClick}>
              <Card3 title="Asylum" id="4" image={image} followLink = "testimonials/asylum"/>
            </div>
            <div className="cardGC" id="5" onClick={this.onClick}>
              <Card3 title="U Visa" id="5" image={image} followLink = "testimonials/uvisa"/>
            </div>
            <div className="cardGC" id="6" onClick={this.onClick}>
              <Card3 title="T Visa" id="6" image={image} followLink = "testimonials/tvisa"/>
            </div>
          </div>
        </div>
      );
    } else if (this.props.card.cardName === "family") {
      return <div>family</div>;
    }
  }
}

GreenCard.propTypes = {
  currentCard: PropTypes.func
};

const mapStatetoProps = state => ({
  auth: state.auth,
  card: state.card,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStatetoProps,
    { currentCard }
  )(GreenCard)
);
