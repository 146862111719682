import React, { Component } from "react";
import { db, auth } from "./Firebase";

import FirebaseCards from "./FirebaseCardsLegal";

class AddLegalNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalNews: [],
      legalNewsInput: "",
      title: "",
      url: ""
    };
    this.updateLegalNews = this.updateLegalNews.bind(this);
    this.logout = this.logout.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    db.ref("-LegalNews/").on("value", snapshot => {
      var legalNews = [];
      legalNews = Object.keys(snapshot.val()).map(legalNews => {
        return {
          id: legalNews,
          legalNews: snapshot.val()[legalNews].currentLegalNews,
          Active: snapshot.val()[legalNews].Active,
          date: snapshot.val()[legalNews].date,
          title: snapshot.val()[legalNews].title,
          url: snapshot.val()[legalNews].url
        };
      });
      this.setState({
        legalNews: legalNews
      });
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  updateLegalNews() {
    var date = new Date();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    var exactDate = month + "/" + day + "/" + year;
    db.ref()
      .child("/-LegalNews")
      .push({
        currentLegalNews: this.state.legalNewsInput,
        date: exactDate,
        title: this.state.title,
        url: this.state.url,
        Active: false
      });
    this.setState({
      legalNewsInput: ""
    });
  }

  logout() {
    auth.signOut().then(() => {
      this.setState({
        user: null,
        email: "",
        password: ""
      });
    });
  }
  render() {
    const legalNews =
      this.state.legalNews.length === 0 ? (
        <h1>loading</h1>
      ) : (
        this.state.legalNews.map((legalNews, index) => {
          return <FirebaseCards legalNews={legalNews} />;
        })
      );
    return (
      <div className="dashboard__content">
        <h3>
          <strong>Add Legal News</strong>
        </h3>
        <div>
          <textarea
            type="text"
            placeholder="Add Title..."
            value={this.state.title}
            onChange={this.handleChange}
            name="title"
            className="titleInput"
          />
          <textarea
            type="text"
            placeholder="Add Url..."
            value={this.state.url}
            onChange={this.handleChange}
            name="url"
            className="urlInput"
          />
        </div>
        <textarea
          type="text"
          placeholder="Add Legal News..."
          value={this.state.legalNewsInput}
          onChange={this.handleChange}
          name="legalNewsInput"
          className="legalNewsInput"
        />
        <button onClick={this.updateLegalNews} className="submitButton">
          Submit
        </button>
        {legalNews}
      </div>
    );
  }
}

export default AddLegalNews;
