import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../styles/testimonialMain.scss";

import QuoteSVG from "../images/icon-thumbs-up.svg";
import firebase, { db } from "../firebase/Firebase";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Fade from "react-reveal/Fade";

import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

export default class TestimonialsMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonials: [
        {
          id: "",
          TestimonialText: ""
        }
      ]
    };
  }

  componentWillMount() {
    var TestimonialsRef = firebase
      .database()
      .ref("Testimonials")
      .once("value")
      .then(snapshot => {
        console.log(snapshot.val());
        const testimonial = Object.keys(snapshot.val()).map(testimonial => {
          return {
            id: testimonial,
            TestimonialText: snapshot.val()[testimonial].TestimonialText,
            active: snapshot.val()[testimonial].Active,
            category: snapshot.val()[testimonial].Category
          };
        });
        console.log("HEY");
        console.log(testimonial);
        const page = this.props.match.params.testimonial;
        this.setState({
          testimonials: testimonial.filter(function(test) {
            return test.category === page;
          })
        });
      });
  }
  render() {
    const testimonials = this.state.testimonials.map((testimonial, index) => {
      console.log(this.state.testimonials);
      return (
        <div className="cardTest">
          <div className="quote">
            <div className="line-small" />
            <img src={QuoteSVG} alt="logo" className="eye-svg" />
            <div className="line-small" />
          </div>
          <p className="card-1-paragraph">{testimonial.TestimonialText}</p>
        </div>
      );
    });
    return (
      <div className="testimonialsMain">
        <Navbar />
        <div className="testimonialsMain__content">
          <div className="testimonials-title">
            <h1 className="testimonials-title-text">PEOPLE WE'VE HELPED</h1>
            <div className="line-medium" />
          </div>
          <div className="quote">
            <div className="line-small" />
            <img src={QuoteSVG} alt="logo" className="eye-svg" />
            <div className="line-small" />
          </div>
          <Slider>
            {this.state.testimonials.map((testimonial, index) => (
              <div className="cardTest">
                <div className="inner">
                  <p>{testimonial.TestimonialText}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <Footer />
      </div>
    );
  }
}
