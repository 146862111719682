import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarNav,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "mdbreact";

import ContactText from "./ContactText";

import { Link as ScrollLink, Events, scrollSpy } from "react-scroll";

import Fade from "react-reveal/Fade";

import "../styles/navbar.css";

class FullPageIntroWithFixedTransparentNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  componentDidMount() {
    Events.scrollEvent.register("begin", function(to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function(to, element) {
      console.log("end", arguments);
    });

    scrollSpy.update();
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  };

  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  };

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  render() {
    return (
      <div>
        <div className="textSection">
          <ContactText />
        </div>
        <Navbar color="white lighten-1" dark expand="lg" fixed="top" scrolling>
          <Container>
            <ScrollLink
              to="#"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <NavbarBrand className="NavLink">
                <NavLink to="/" className="NavLink">
                  <img
                    src="https://imgur.com/2ZNjwgw.png"
                    alt="Logo"
                    className="logo"
                  />
                </NavLink>
              </NavbarBrand>
              {/* <NavbarToggler onClick={this.toggleCollapse} /> */}
            </ScrollLink>
            {!this.state.isWideEnough && (
              <NavbarToggler onClick={this.onClick} className="toggler" />
            )}
            <Collapse isOpen={this.state.collapse} navbar className="toggler">
              <NavbarNav right>
                <NavItem className="paDropDown1">
                  <Dropdown>
                    <DropdownToggle className="NavLink" nav caret>
                      <strong className="navbtn">
                        PRACTICE AREAS & SERVICES
                      </strong>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem href="#!">
                        <NavLink to="/services" className="NavLink">
                          <strong className="navbtn">IMMIGRATION LAW</strong>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem href="#!">
                        <NavLink to="/practices" className="NavLink">
                          <strong className="navbtn">
                            PRACTICE AREAS IN IL
                          </strong>
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
                <NavItem>
                  <ScrollLink
                    to="profile"
                    spy={true}
                    smooth={true}
                    offset={-65}
                    duration={500}
                  >
                    <NavLink to="/" className="NavLink">
                      <strong className="navbtn">PROFILE</strong>
                    </NavLink>
                  </ScrollLink>
                </NavItem>
                <NavItem>
                  <ScrollLink
                    to="testimonials"
                    spy={true}
                    smooth={true}
                    offset={-65}
                    duration={500}
                  >
                    <NavLink to="/" className="NavLink">
                      <strong className="navbtn">PEOPLE WE'VE HELPED</strong>
                    </NavLink>
                  </ScrollLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/legalnews" className="NavLink">
                    <strong className="navbtn">LATEST NEWS</strong>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <ScrollLink
                    to="contactMe"
                    spy={true}
                    smooth={true}
                    offset={-65}
                    duration={500}
                  >
                    <NavLink to="/" className="NavLink">
                      <strong className="navbtn">CONTACT ME</strong>
                    </NavLink>
                  </ScrollLink>
                </NavItem>
                <NavItem className="paDropDown2">
                  <Dropdown>
                    <DropdownToggle className="NavLink" nav caret>
                      <strong className="navbtn">
                        PRACTICE AREAS & SERVICES
                      </strong>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem href="#!">
                        <NavLink to="/services" className="NavLink">
                          <strong className="navbtn">IMMIGRATION LAW</strong>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem href="#!">
                        <NavLink to="/practices" className="NavLink">
                          <strong className="navbtn">
                            PRACTICE AREAS IN IL
                          </strong>
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
              </NavbarNav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default FullPageIntroWithFixedTransparentNavbar;
