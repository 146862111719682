import React, { Component } from "react";
import "../styles/scss/Card2.scss";
import title_scale from "../images/title_scale.png";

export default class Card3 extends Component {
  render() {
    return (
      <div className="blog-slider1">
        <div className="blog-slider1__wrp swiper-wrapper">
          <div className="blog-slider1__item swiper-slide">
            <div className="blog-slider1__img">
              <img src={this.props.image} alt="" />
            </div>
            <div className="blog-slider1__content">
              <span className="blog-slider1__code">
                <img
                  className="alignnone size-full wp-image-3559"
                  src={title_scale}
                  alt=""
                  width="31"
                  height="25"
                />
              </span>
              <div className="blog-slider1__title">{this.props.title}</div>
              <div className="blog-slider1__text">
                Click "READ MORE" to view more information about{" "}
                {this.props.title}.
              </div>
              <a
                href="javascript:void(0)"
                className="blog-slider1__button"
                id={this.props.id}
              >
                READ MORE
              </a>
              {this.props.followLink ? (
                <a
                  href={this.props.followLink}
                  className="blog-slider1__button1"
                >
                  TESTIMONIALS
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div className="blog-slider1__pagination" />
      </div>
    );
  }
}
