import { GET_CARDS, DEFAULT_CARDS } from "../actions/types";

const initialState = {
  section: "main",
  subsection: "",
  subsubsection: "",
  subsubsubsection: "",
  links: ["Home"],
  remove: []
};

export default function(state = initialState, action) {
  console.log(action);
  switch (action.type) {
    case GET_CARDS:
      return {
        section: action.payLoad.section,
        subsection: action.payLoad.subsection,
        subsubsection: action.payLoad.subsubsection,
        subsubsubsection: action.payLoad.subsubsubsection,
        links: [...state.links, action.payLoad.links]
      };
    case DEFAULT_CARDS:
      return {
        section: action.payLoad.section,
        subsection: action.payLoad.subsection,
        subsubsection: action.payLoad.subsubsection,
        subsubsubsection: action.payLoad.subsubsubsection,
        links: action.payLoad.remove
      };
    default:
      return state;
  }
}
