import React, { Component } from "react";
import QuoteSVG from "../images/icon-thumbs-up.svg";
import firebase from "../firebase/Firebase";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

class Testimonials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonials: [
        {
          id: "",
          TestimonialText: ""
        }
      ]
    };
  }

  componentWillMount() {
    var TestimonialsRef = firebase
      .database()
      .ref("Testimonials")
      .once("value")
      .then(snapshot => {
        const testimonial = Object.keys(snapshot.val()).map(testimonial => {
          return {
            id: testimonial,
            TestimonialText: snapshot.val()[testimonial].TestimonialText,
            active: snapshot.val()[testimonial].Active
          };
        });
        this.setState({
          testimonials: testimonial.filter(function(test) {
            return test.active === true;
          })
        });
      });
  }

  renderTestimonial() {
    return this.state.testimonials.forEach(testimonial => {
      return (
        <div className="cardTest">
          <div className="quote">
            <div className="line-small" />
            <img src={QuoteSVG} alt="logo" className="eye-svg" />
            <div className="line-small" />
          </div>
          <p className="card-1-paragraph">
            {testimonial.TestimonialText.TestimonialText}
          </p>
        </div>
      );
    });
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const testimonials = this.state.testimonials.map((testimonial, index) => {
      return (
        <div className="cardTest">
          <div className="quote">
            <div className="line-small" />
            <img src={QuoteSVG} alt="logo" className="eye-svg" />
            <div className="line-small" />
          </div>
          <p className="card-1-paragraph">{testimonial.TestimonialText}</p>
        </div>
      );
    });

    return (
      <div className="testimonials">
        <div className="testimonials-title">
          <h1 className="testimonials-title-text">PEOPLE WE'VE HELPED</h1>
          <div className="line-medium" />
        </div>
        <div className="quote">
          <div className="line-small" />
          <img src={QuoteSVG} alt="logo" className="eye-svg" />
          <div className="line-small" />
        </div>
        <Slider>
          {this.state.testimonials.map((testimonial, index) => (
            <div className="cardTest">
              <div className="inner">
                <p>{testimonial.TestimonialText}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default Testimonials;
