import React, { Component } from 'react';
import '../styles/contactMe.css';

import Fade from 'react-reveal/Fade';

export default class PracticeAreas extends Component {
	render() {
		return (
			<div className="contactUs">
				<div className="contactUs-headerPane">
					<Fade>
						<p className="contactUs-headerTxt">CONTACT ME</p>
						<p className="contactUs-contactName">Bayarjargal Sereenen</p>
					</Fade>
				</div>
				<div className="contact-areaPane">
					<div className="contact-areaPane__info">
						<div className="contact-address__title">
							<Fade>
								<strong className="bold">OUR</strong> ADDRESS
							</Fade>
						</div>

						<div className="contact-info">
							<Fade>
								<div className="contact-info__address">3657 W. Lawrence Ave, Chicago IL 60625</div>
								<div className="contact-info__email">
									<span className="bold">Email: </span>
									tsegtslawgroup999@gmail.com
								</div>
								<div className="contact-info__tel">
									<span className="bold">Phone: </span>
									773-999-9933
								</div>
								<div className="contact-info__tel">
									<span className="bold">Phone: </span>
									773-387-7901
								</div>
							</Fade>
						</div>
					</div>

					<div className="contact-form">
						<Fade>
							<div className="contact-form__submissionInfo">
								For any general inquiries, please fill in the following contact form:
							</div>
							{/* <div className="contact-form__forms"> */}
							<form
								method="POST"
								action="https://formspree.io/tsegtslaw@gmail.com"
								className="contact-form__forms"
							>
								<input
									type="text"
									className="contact-form__forms-name"
									placeholder="Name"
									name="Name"
								/>
								<input
									name="Email"
									type="email"
									className="contact-form__forms-email"
									placeholder="Email"
								/>
								<input name="Subject" className="contact-form__forms-subject" placeholder="Subject" />
								<textarea
									name="Message"
									className="contact-form__forms-message"
									placeholder="Message"
								/>
								<button className="contact-form__forms-submit">Submit</button>
							</form>
							{/* </div> */}
						</Fade>
					</div>
				</div>
			</div>
		);
	}
}
