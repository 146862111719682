import React, { Component } from "react";
import "../styles/certificates.scss";

import Fade from "react-reveal/Fade";

class Certificates extends Component {
  render() {
    return (
      <div className="certificates-row">
        <img
          src="https://imgur.com/so9hoJF.png"
          alt=""
          className="certificates-image-1"
        />
        <img
          src="https://imgur.com/wvojJ0B.png"
          alt=""
          className="certificates-image-2"
        />
        <img
          src="https://imgur.com/cEpcHUU.png"
          alt=""
          className="certificates-image-3"
        />
        <img
          src="https://imgur.com/d0hXeyg.png"
          alt=""
          className="certificates-image-4"
        />
      </div>
    );
  }
}

export default Certificates;
