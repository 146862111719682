import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { currentCard } from "../actions/cardActions";

import image from "../images/write-593333_1920.jpg";
import "../styles/employment.css";

import Card3 from "./Card3";
import Description from "./Description";

class Employment extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(n) {
    if (n.target.id === "1") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "2",
          subsubsubsection: "1",
          links: ["Persons of Extraordinary Abilities"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "2") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "2",
          subsubsubsection: "2",
          links: ["Outstandind Professors and Researchers"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "3") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "2",
          subsubsubsection: "3",
          links: ["National Interest Waivers"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "4") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "2",
          subsubsubsection: "4",
          links: ["Religious Workers"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "5") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "2",
          subsubsubsection: "5",
          links: ["PERM Processing"]
        }
      };
      this.props.currentCard(this.payload.card);
    }
  }

  render() {
    if (this.props.card.subsubsubsection === "1")
      return (
        <Description
          title="Persons of Extraordinary Abilities"
          description={
            <div className="post-right_body">
              <div>
                <h2>Persons of Extraordinary Abilities</h2>
                <br />
                <div>
                  <p>
                    Persons of extraordinary ability (EB-1) may become US legal
                    permanent residents, without the labor certification
                    process. Even, these people do not need an employer to
                    submit a petition to the USCIS for them, because they are
                    allowed to “self-petition”.
                  </p>
                  <p>
                    These people must demonstrate extraordinary ability in the
                    sciences, arts, education, business, or athletics, through
                    sustained national or international acclaim. Their
                    achievements must be recognized in the field through
                    extensive documentation. Further, the immigration law
                    dictates that a person of extraordinary ability is one who
                    belongs to that “small percentage” who has “risen to the
                    very top of the field of endeavor”. We can get green cards
                    for persons of extraordinary ability including scientists,
                    physicians, researchers, artists, reporters, athletes,
                    entrepreneurs and performers.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "2")
      return (
        <Description
          title="Outstanding Professors and Researchers"
          description={
            <div className="post-right_body">
              <div>
                <h2>Outstanding Professors and Researchers</h2>
                <br />
                <div>
                  <p>
                    Outstanding professors and researchers can get green cards
                    in the United States without being required to go through
                    the PERM(labor certification) process. <br />
                    To be considered outstanding, a professor or researcher must
                    be internationally recognized as outstanding in his or her
                    academic area and must meet certain other requirements such
                    as three years teaching or research experience in the field
                    and arriving to take a tenure or tenure-track position or
                    comparable research position at a university or other
                    institution of higher education.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "3")
      return (
        <Description
          title="National Interest Waivers"
          description={
            <div className="post-right_body">
              <div>
                <h2>National Interest Waivers</h2>
                <br />
                <div>
                  <p>
                    Generally, if you qualify for “EB-2″ category (Workers
                    Holding Advanced Degrees and Persons with Exceptional
                    Ability in the Arts, Sciences and Business), you are subject
                    to undergo the labor certification process. However, an
                    exception might apply to you and you may get a national
                    interest waiver, if your employment is in the “national
                    interest.” The national waiver has very restrictive
                    requirements and it is very difficult to get one. <br />
                    When a person is distinguished in his or her field, the PERM
                    or labor certification process may be contrary to the
                    national interest since they do not result in an approval
                    where a U.S. worker meets the minimum qualifications for the
                    job. For example, if a distinguished scientist has been
                    working on a project to treat a highly contagious disease,
                    then it would be contrary to the national interest, to have
                    the employer to hire a minimally qualified person for the
                    job.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "4")
      return (
        <Description
          title="Religious Workers"
          description={
            <div className="post-right_body">
              <div>
                <h2>Religious Workers</h2>
                <br />
                <div>
                  <p>
                    To be eligible for permanent residence in the religious
                    worker category, you must:
                  </p>
                  <ul>
                    <li>
                      Have been a member of a bona fide non-profit religious
                      denomination for at least two years before the filing of a
                      petition; and
                    </li>
                    <li>
                      {" "}
                      Have been working continuously for the past 2 years
                      immediately prior to filing the immigrant petition:
                    </li>
                    <li>
                      As a religious minister in a religious vocation either
                      professional or non-professional capacity, or In a
                      religious occupation either professional or
                      nonprofessional capacity; and
                    </li>
                    <li>
                      Seek to enter the United States solely to carry out such
                      religious occupation of the employer’s denomination.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "5")
      return (
        <Description
          title="PERM Processing"
          description={
            <div className="post-right_body">
              <div>
                <h2>PERM processing</h2>
                <br />
                <div>
                  <p>
                    Vast majority of people who get green cards through
                    employment based petitions need to undergo the PERM (labor
                    certification) process. Here, employers submit PERM
                    applications to the US Department of Labor showing that they
                    have tried to hire U.S. citizen or green card holders for
                    the job, but have not been successful. The first step in the
                    labor certification process is to electronically request a
                    prevailing wage determination (PWD) from the U.S. Department
                    of Labor (DOL). The employer provides the DOL with job
                    duties, requirements and location. <br />
                    Then, the employer must conduct good faith recruitment
                    effort to see if there are any qualified U.S. workers for
                    the job. They are required to advertise the job in a
                    newspaper of general circulation in the area of intended
                    employment. The employer must also advertise the job with
                    the state workforce agency and post a notice of the job
                    opportunity at the work site. Finally, if no qualified U.S.
                    workers apply for the job, the employer must prepare and
                    submit a PERM application electronically to the DOL. It
                    takes DOL several months to issue a decision the PERM
                    application.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "2") {
      return (
        <div className="servicesEmployment">
          <div className="cardsEmployment">
            <div className="cardEmployment" id="1" onClick={this.onClick}>
              <Card3
                title="Persons of Extraordinary Abilities"
                id="1"
                image={image}
                followLink = "testimonials/PoEA"
              />
            </div>
            <div className="cardEmployment" id="2" onClick={this.onClick}>
              <Card3
                title="Outstanding Professors and Researchers"
                id="2"
                image={image}
                followLink = "testimonials/professors"
              />
            </div>
            <div className="cardEmployment" id="3" onClick={this.onClick}>
              <Card3 title="National Interest Waivers" id="3" image={image} followLink = "testimonials/nationalinterest"/>
            </div>
            <div className="cardEmployment" id="4" onClick={this.onClick}>
              <Card3 title="Religious Workers" id="4" followLink = "testimonials/religiousworkers"/>
            </div>
            <div className="cardEmployment" id="5" onClick={this.onClick}>
              <Card3 title="PERM Processing" id="5" image={image} followLink = "testimonials/perm"/>
            </div>
          </div>
        </div>
      );
    } else if (this.props.card.cardName === "family") {
      return <div>family</div>;
    }
  }
}

Employment.propTypes = {
  currentCard: PropTypes.func
};

const mapStatetoProps = state => ({
  auth: state.auth,
  card: state.card,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStatetoProps,
    { currentCard }
  )(Employment)
);
