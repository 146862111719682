import React, { Component } from "react";
import "../styles/scss/Descr.scss";

export default class Description extends Component {
  render() {
    return (
      <div className="description">
        <div className="post">
          <div className="post-left">
            <div className="post-left_title">{this.props.title}</div>
            <div className="post-left_border" />
          </div>
          <div className="post-right">{this.props.description}</div>
        </div>
      </div>
    );
  }
}
