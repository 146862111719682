import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { currentCard } from "../actions/cardActions";

import image from "../images/lady-justice-2388500_1920.jpg";
import "../styles/deportation.css";

import Card3 from "./Card3";
import Description from "./Description";

class Deportation extends Component {
  constructor() {
    super();
    this.state = {
      card: {
        current: "main",
        previous: ""
      }
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick(n) {
    if (n.target.id === "1") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          subsubsection: "1",
          links: ["Asylum"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "2") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          subsubsection: "2",
          links: ["Adjustment of Status"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "3") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          subsubsection: "3",
          links: ["Cancellation of Removal For Illegal Aliens"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "4") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          subsubsection: "4",
          links: ["Cancellation of Removal for Green Card Holders"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "5") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          subsubsection: "5",
          links: ["212C Waiver"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "6") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          subsubsection: "6",
          links: ["212H Waiver"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "7") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          subsubsection: "7",
          links: ["Crime of Moral Turpitude"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "8") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          subsubsection: "8",
          links: ["Aggravated Felony"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "9") {
      this.payload = {
        card: {
          section: "1",
          subsection: "4",
          subsubsection: "9",
          links: ["212(I) Waiver"]
        }
      };
      this.props.currentCard(this.payload.card);
    }
  }

  render() {
    if (this.props.card.subsubsection === "1")
      return (
        <Description
          title="Asylum"
          description={
            <div className="post-right_body">
              <div>
                <h2>Asylum</h2>
                <br />
                <div>
                  <p>
                    You may avoid deportation through a successful asylum
                    petition. You qualify for asylum in the US if you have been
                    persecuted or have a “well-founded fear of persecution” in
                    your country based on (1) political opinion, (2) religion,
                    (3) race, (4) nationality, or (5) membership of a particular
                    a social group. If you are outside the US, you may apply for
                    refugee status based on the above criteria. Your fear of
                    persecution must be either by the government of your country
                    or by a group that the government is unable or unwilling to
                    control.
                    <br />
                    If you are able to establish past persecution, a presumption
                    arises that you have established a well-founded fear of
                    persecution. The burden of proof shifts to the government to
                    demonstrate that circumstances have changed and that you no
                    longer have a well-founded fear of persecution or that you
                    could avoid persecution by relocating in another part of
                    your country and that it would be reasonable for you to do
                    so.
                    <br />
                    If you are in lawful immigration status, you can submit an
                    application for asylum directly with the appropriate USCIS
                    Service Center. Should your application be denied, you will
                    remain in lawful status. However, if you are not in lawful
                    status, should your application not be approved by the
                    USCIS, you will be placed in removal proceedings. If you are
                    in removal proceedings before an immigration judge, in
                    addition to applying for asylum, you may be eligible to
                    apply for withholding of removal and for relief under the
                    Convention Against Torture.
                    <br />
                    If the government can demonstrate that you have firmly
                    resettled in a 3rd country, you are ineligible for asylum,
                    withholding of removal and Convention Against Torture.
                    Generally, you must apply for asylum within 1 year, after
                    arriving in the US. However, there are several exceptions to
                    this rule. Once your asylum application has been pending for
                    over 150 days, you may apply for a work permit. If you are
                    granted asylum, and your spouse and/or children are outside
                    the US, then you may bring them to the US as asylees. One
                    year after you are granted asylum, you may apply for a green
                    card.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "2")
      return (
        <Description
          title="Adjustment of Status"
          description={
            <div className="post-right_body">
              <div>
                <h2>Adjustment of Status</h2>
                <br />
                <div>
                  <p>
                    A person who qualifies to be permanent resident may be able
                    to apply to obtain a green card without leaving the U.S.
                    This procedure is called adjustment of status. When a person
                    applies for adjustment of status, we petition for a work
                    permit (Employment Authorization Document a.k.a EAD) and, if
                    he is eligible, for a travel permit (“advance parole”).
                    Generally, an applicant for adjustment of status must have
                    entered the U.S. legally and have never violated his
                    immigration status. However, there are some exceptions to
                    this general rule. Section 245(i) of the law provides that
                    certain persons with old priority dates may pay a penalty
                    fee and adjust their status despite entering the U.S.
                    illegally or violating or overstaying their nonimmigrant
                    status. Section 245(k) provides that if a person is applying
                    for adjustment of status pursuant to an employment-based
                    immigrant visa petition, he is eligible to do so as long as
                    he has not been out of status for over 180 days since his
                    most recent admission to the U.S.
                    <br />
                    Also, persons who are immediate relatives (parents, spouses
                    and children of U.S. citizens) may adjust their status if
                    they entered the U.S. lawfully even if they overstayed or
                    worked without authorization without having to pay a penalty
                    fee.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "3")
      return (
        <Description
          title="Cancellation of Removal for Illegal Aliens"
          description={
            <div className="post-right_body">
              <div>
                <h2>Cancellation of Removal for Illegal Aliens</h2>
                <br />
                <div>
                  <p>
                    If you have been placed in removal proceedings and you have
                    lived in the United States for a long period of time, you
                    may apply for Cancellation of Removal for non-LPRs before an
                    Immigration Judge, if you satisfy the following conditions:
                  </p>
                  <ul>
                    <li>
                      You have been physically present in the U.S. for a
                      continuous period of ten years prior to the start of
                      removal proceedings. “Continuous” means that you were not
                      out of the U.S. for more than 90 days at a time, or 180
                      days in the aggregate, during the ten-year period;
                    </li>
                    <li>
                      You have been a person of good moral character for ten
                      years;
                    </li>
                    <li>
                      You are not inadmissible under §212(a)(2) or (3) (criminal
                      and security grounds) or deportable under §237(a)(1)(G)
                      (marriage fraud), (2) (criminal grounds), (3) (failure to
                      register and falsification of documents) or (4) (security
                      and related grounds); and
                    </li>
                    <li>
                      {" "}
                      Your removal would result in exceptional and extremely
                      unusual hardship to your spouse, parent, or child, who is
                      a citizen of the United States or a lawful permanent
                      resident. Exceptional and Extremely Unusual Hardship
                    </li>
                  </ul>
                  <p>
                    To establish “exceptional and extremely unusual hardship,”
                    you must show that your qualifying relative would suffer
                    hardship “substantially beyond” that would ordinarily result
                    from your removal from the United States. Hardship to the
                    respondent is not considered. The Judge will consider your
                    qualifying relative’s age, health, length of residence in
                    the United States, as well as family and community ties in
                    the U.S. and abroad. All hardship factors are considered in
                    the aggregate. There are special or relaxed rules for
                    cancellation of removal for non-LPRs apply to battered
                    spouses and children.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "4")
      return (
        <Description
          title="CANCELLATION OF REMOVAL FOR PERMANENT RESIDENTS"
          description={
            <div className="post-right_body">
              <div>
                <h2>Cancellation of Removal for Permanent Residents</h2>
                <br />
                <div>
                  <p>
                    If you are a green card holder that the Immigration is
                    trying to deport, you may apply to the Immigration Judge for
                    Cancellation of Removal for Permanent Residents, if you meet
                    the following conditions:
                  </p>
                  <ul>
                    <li>
                      You have been a lawful permanent resident of the U.S. for
                      at least five years
                    </li>
                    <li>
                      You have lived continuously in the U.S. for a minimum of
                      seven years after being admitted to the U.S. in any status
                      (prior to the institution of removal proceedings)
                    </li>
                    <li>You have not been convicted of an aggravated felony</li>
                    <li>
                      You are not inadmissible from the U.S. on security grounds
                    </li>
                  </ul>
                  <p>
                    In order to be granted cancellation of removal for permanent
                    residents, you must be able to convince the Immigration
                    Judge that the positive factors in your case outweigh the
                    negative factors. Positive factors include: (1) Family ties
                    within the U.S.; (2) Long time residency in the U.S.; (3)
                    Hardship to person and immediate family; (4) Service in U.S.
                    Armed Forces; (5) Employment history; (6) Ownership of
                    property and business ties; (7) Service to the community;
                    (8) Rehabilitation (if criminal record exists); and (9) Good
                    moral character. Negative factors include: (1) Nature and
                    circumstances of. violations; (3) Criminal record,
                    especially the seriousness and recency of any convictions;
                    and (4) Other evidence of bad character. The following
                    classes of persons are ineligible for cancellation of
                    removal for permanent residents:
                  </p>
                  <ul>
                    <li>
                      Persons who have been convicted of aggravated felonies
                    </li>
                    <li>Certain crewmen</li>
                    <li>
                      Exchange visitors (in “J” status) who received medical
                      training in the U.S
                    </li>
                    <li>Persons who have persecuted others</li>
                    <li>
                      Persons who have previously been granted cancellation of
                      removal, suspension of deportation or a waiver under
                      section 212(c)
                    </li>
                    <li>
                      Persons who committed certain criminal offenses prior to
                      the accrual of the required seven years
                    </li>
                  </ul>
                  <p>
                    It is important to note that if you obtained your green card
                    through fraud, you are ineligible to apply for cancellation
                    of removal.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "5")
      return (
        <Description
          title="212C Waiver"
          description={
            <div className="post-right_body">
              <div>
                <h2>212C Waiver</h2>
                <br />
                <div>
                  <p>
                    If you are a green card holder or legal permanent residence
                    of the United States and have been placed in removal
                    proceedings because of a criminal activity, you could seek a
                    waiver of your convictions and avoid deportation. Your
                    eligibility to apply for a waiver is depend on the type of
                    crime you committed and the date on which you were
                    convicted. Certain lawful permanent residents can apply for
                    discretionary relief under section 212(c) of the Immigration
                    and Nationality Act.
                  </p>
                  <p>
                    The requirements to apply for and receive a 212c waiver
                    include:
                  </p>
                  <ul>
                    <li>You pled guilty to the crime prior to April 1, 1997</li>
                    <li>
                      You have been a lawful permanent resident for at least 5
                      years
                    </li>
                    <li>
                      You are returning to a lawful, un-relinquished residence
                      of at least 7 consecutive years
                    </li>
                    <li>
                      You are not subject to deportation or removal on the
                      grounds of terrorism or national security
                    </li>
                    <li>
                      You are not unlawfully in the US due to a previous
                      immigration offense
                    </li>
                    <li>
                      You have not been convicted of a firearms offense or an
                      aggravated felony offense for which you served over 5
                      years
                    </li>
                  </ul>
                  <p>
                    You are ineligible to receive a 212c waiver if you have
                    departed and are currently outside the US, you have
                    illegally returned after deportation or removal, or you are
                    present in the US without having been admitted or paroled.
                    It is important to note that the 212c waiver is a
                    discretionary. Therefore, even if you are eligible to apply
                    for a 212c waiver, the Immigration Judge will decide on a
                    case-by-case basis whether or not to grant you this relief.
                    <br />
                    The Judge will look closely at the balance of positive
                    factors versus negative factors in your application.
                    Positive factors include family ties in the US, long time
                    residence in the US, hardship to you and your family if you
                    were deported, property ownership, business ties,
                    demonstrated value and service to the community, genuine
                    rehabilitation and evidence that you are person of good
                    character. Negative factors can include the nature,
                    seriousness and recency of your criminal record and evidence
                    that you are a person of bad moral character.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "6")
      return (
        <Description
          title="212H Waiver"
          description={
            <div className="post-right_body">
              <div>
                <h2>212H Waiver</h2>
                <br />
                <div>
                  <p>
                    If you are a permanent resident have a criminal conviction
                    that makes you deportable, you may avoid deportation. You
                    may be able to do so, if you qualify for a 212(h) waiver.
                    <br />
                    Even if you are deportable because you were convicted of one
                    of the following crimes, you may still be eligible for a
                    212(h) waiver:
                  </p>
                  <ol>
                    <li>You pled guilty to the crime prior to April 1, 1997</li>
                    <li>
                      You have been a lawful permanent resident for at least 5
                      years
                    </li>
                    <li>
                      You are returning to a lawful, un-relinquished residence
                      of at least 7 consecutive years
                    </li>
                    <li>
                      You are not subject to deportation or removal on the
                      grounds of terrorism or national security
                    </li>
                    <li>
                      You are not unlawfully in the US due to a previous
                      immigration offense
                    </li>
                    <li>
                      You have not been convicted of a firearms offense or an
                      aggravated felony offense for which you served over 5
                      years
                    </li>
                  </ol>
                  <h4>15-Year Waiver</h4>
                  <p>
                    You must demonstrate that the activities for which you are
                    inadmissible occurred more than 15 years ago; that your
                    admission would not be contrary to the national welfare,
                    safety, or security of the US and 3) that you have been
                    rehabilitated.
                  </p>
                  <h4>Extreme Hardship Waiver</h4>
                  <p>
                    If you inadmissible due to any qualifying criminal conduct,
                    you may qualify for a waiver if: <br />
                    (1) you can show that if you were denied admission, your US
                    citizen or green card holder spouse, parent, son or daughter
                    would suffer extreme hardship; and <br />
                    (2) USCIS or the Immigration Judge exercises favorable
                    discretion in your case.
                    <br />
                    Extreme Hardship Factors include whether your qualifying
                    relatives have family ties to the US; the extent of the
                    qualifying relatives’ family ties outside the US; conditions
                    in your home country; financial impact of your departure
                    from the US; and significant health conditions, particularly
                    when tied to an unavailability of suitable medical care in
                    your country.
                  </p>
                  <h4>Battered Spouse Waiver</h4>
                  <p>
                    If your spouse is a US citizen or green card holder and you
                    were battered or subjected to extreme cruelty by your
                    spouse, you may file a VAWA battered spouse petition. The
                    same rules apply to a battered child. If you file such a
                    battered spouse petition applying for a green card but are
                    inadmissible due to qualifying criminal conduct, you can
                    apply for a 212(h) waiver. Applying for a 212(h) waiver is
                    an extremely complex process.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "7")
      return (
        <Description
          title="Crimes of Moral Turpitude"
          description={
            <div className="post-right_body">
              <div>
                <h2>Crime of Moral Turpitude</h2>
                <br />
                <div>
                  <p>
                    A criminal conviction can be a reason for deportation. All
                    immigrants including those applying for a visa or green
                    card, those who already have a visa or green card, and those
                    with green cards applying for US citizenship can be can be
                    deported under US immigration law. Criminal conduct is a
                    common reason why immigrants are placed in removal
                    proceedings, especially if they are convicted of a “crime of
                    moral turpitude.”
                  </p>
                  <ul>
                    <li>
                      There is no specific definition of a crime of moral
                      turpitude in US immigration law, but it generally refers
                      to conduct that “shocks the public conscience as being
                      inherently base, vile or depraved.” It “conflicts with the
                      rules of morality and the person committing the crime has
                      been acting recklessly and often with evil intent.” It is
                      a concept that commonly includes crimes such as:
                    </li>
                    <li>Murder</li>
                    <li>Voluntary or reckless manslaughter</li>
                    <li>Rape</li>
                    <li>
                      You are not unlawfully in the US due to a previous
                      immigration offense
                    </li>
                    <li>Kidnapping</li>
                    <li>Assault with intent to rob or kill</li>
                    <li>Fraud</li>
                    <li>Larceny</li>
                    <li>Intent to harm persons or things</li>
                  </ul>
                  <p>
                    In order to be deported based on committing one crime of
                    moral turpitude, the person must have been convicted within
                    5 years after entering the US. They also must have been
                    sentenced to confinement for at least one year. A crime
                    considered of moral turpitude in which a sentence of less
                    than one year was imposed would not necessarily be
                    considered grounds of deportation.
                    <br />
                    In these types of situations, it is up to the Immigration
                    Judge on a case-by-case basis to determine if it is a crime
                    of moral turpitude. The Judge will usually look at both the
                    language of the law under which the person was convicted and
                    the actual records and facts surrounding the conviction. It
                    is up to them to decide if the crime was intentional and if
                    the offender should be deported from the US.
                    <br />
                    There are some situations in which you may be able to apply
                    for a waiver under section 212(h) of the Immigration and
                    Nationality Act or for cancellation of removal.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "8")
      return (
        <Description
          title="Felony"
          description={
            <div className="post-right_body">
              <div>
                <h2>Aggravated Felony</h2>
                <div>
                  <p>
                    Being convicted of an aggravated felony has serious
                    consequences, under US immigration laws. It used to be the
                    term “aggravated felony” referred only to murder, federal
                    drug trafficking, and illicit trafficking of firearms and
                    destructive devices. Nowadays, an “aggravated felony” does
                    not have to be “aggravated” nor a “felony”. An “aggravated
                    felony” includes many nonviolent offenses that are
                    relatively minor.
                  </p>
                  <p>Examples of Aggravated Felonies</p>
                  <ul>
                    <li>
                      Theft offense, including receipt of stolen property if the
                      term of imprisonment is at least one year;
                    </li>
                    <li>
                      Tax evasion in the case of where the loss to the
                      government exceeds $10,000;
                    </li>
                    <li>
                      Receipt of stolen property if the term of imprisonment is
                      at least one year;
                    </li>
                    <li>
                      Fraud or deceit offenses if the loss to the victim exceeds
                      $10,000;
                    </li>
                    <li>Attempt to commit an aggravated felony;</li>
                    <li>
                      Counterfeiting – if the term of imprisonment is at least
                      one year
                    </li>
                    <li>Offense related to alien smuggling</li>
                  </ul>
                  <p>
                    Although the term “aggravated felony” comes from federal
                    law, it must be applied to crimes under state laws. In some
                    cases, state crimes that may sound negligible to most
                    people, do not involve violence and are not felonies are
                    nonetheless viewed as aggravated felonies by federal
                    immigration authorities. A conviction for an aggravated
                    felony makes it difficult to receive any kind of immigration
                    benefit such as a green card, naturalization, asylum,
                    temporary worker status or even a visitor’s visa.
                  </p>
                  <p>
                    Deportation without a Removal Hearing Before an Immigration
                    Judge
                  </p>
                  <p>
                    Immigrants who are convicted of an “aggravated felony” have
                    few legal protections. For example, a non-LPR who is
                    convicted of an aggravated felony may be administratively
                    deported from the US without a hearing before an Immigration
                    Judge.
                  </p>
                  <p>Mandatory Detention</p>
                  <p>
                    Immigration and Customs Enforcement (ICE) is required to
                    detain any non-US citizen convicted of an aggravated felony
                    upon release from criminal custody.
                    <br />
                    Ineligible for Asylum
                  </p>
                  <p>
                    Any non-U.S. citizen convicted of an aggravated felony is
                    ineligible for asylum, even if he or she fears persecution
                    if returned to his or her native country.
                    <br />
                    Ineligible for Cancellation of Removal
                  </p>
                  <p>
                    Any non-U.S. citizen convicted of an aggravated felony is
                    ineligible for cancellation of removal which is a form of
                    relief allowing Immigration Judges to allow persons to
                    remain in the US.
                  </p>
                  <p>Ineligible for Certain Waivers of Inadmissibility</p>
                  <p>
                    Any non-US citizen who is convicted of an aggravated felony
                    is inadmissible to the US. Persons in the US who have never
                    had a green card may apply for a 212(h) waiver to excuse
                    past criminal conduct, but only if they can show “extreme
                    hardship” to a qualifying US citizen or lawful permanent
                    resident relative.
                  </p>
                  <p>Ineligible for Voluntary Departure</p>
                  <p>
                    Non-US citizens convicted of an “aggravated felony” are
                    ineligible for voluntarily departure. Instead, they will be
                    ordered removed from the US.
                  </p>
                  <p>
                    Permanent Inadmissibility Following Removal from the United
                    States
                  </p>
                  <p>
                    Anyone removed from the US after being convicted of an
                    aggravated felony is permanently inadmissible, unless he or
                    she receives a special waiver from the Department of
                    Homeland Security.
                    <br />
                    Enhanced Penalties for Illegally Reentering the United
                    States
                  </p>
                  <p>
                    Anyone removed from the US following a conviction for an
                    aggravated felony, and who subsequently reenters the country
                    illegally, may be imprisoned for up to 20 years.
                  </p>
                  <p>
                    This area of law is very complicated and it is advisable
                    that you consult both an experienced immigration attorney
                    and an experienced criminal defense attorney before pleading
                    to any charges that may affect your immigration rights. The
                    table below provides insight into what are considered
                    aggravated felony convictions under federal immigration law.
                  </p>
                  <p>
                    “Aggravated Felonies” in the Immigration Context
                    <br />
                    Aggravated Felony Citation
                  </p>
                  <p>
                    Murder, Rape, or Sexual Abuse of a Minor INA 101(a)(43)(A)
                  </p>
                  <p>
                    Illicit Trafficking in Controlled Substance INA
                    101(a)(43)(B)
                  </p>
                  <p>
                    Illicit Trafficking in Firearms or Destructive Devices INA
                    101(a)(43)(C)
                  </p>
                  <p>
                    Money Laundering Offenses (over $10,000) INA 101(a)(43)(D)
                  </p>
                  <p>
                    Explosive Materials and Firearms Offenses INA
                    101(a)(43)(E)(i)–(iii)
                  </p>
                  <p>
                    Crime of Violence (imprisonment term of at least 1 yr) INA
                    101(a)(43)(F)
                  </p>
                  <p>
                    Theft Offense (imprisonment term of at least 1 yr) INA
                    101(a)(43)(G)
                  </p>
                  <p>Demand for or Receipt of Ransom INA 101(a)(43)(H)</p>
                  <p>Child Pornography Offense INA 101(a)(43)(I)</p>
                  <p>
                    Racketeering, Gambling (imprisonment term of at least 1 yr)
                    INA 101(a)(43)(J)
                  </p>
                  <p>
                    Prostitution Offenses (managing, transporting, trafficking)
                    INA 101(a)(43)(K)(i)–(iii)
                  </p>
                  <p>
                    Gathering or Transmitting Classified Information INA
                    101(a)(43)(L)(i)–(iii)
                  </p>
                  <p>
                    Fraud or Deceit Offenses or Tax Evasion (over $10,000) INA
                    101(a)(43)(M)(i), (ii)
                  </p>
                  <p>Alien Smuggling INA 101(a)(43)(N)</p>
                  <p>
                    Illegal Entry or Reentry by Removed Aggravated Felon INA
                    101(a)(43)(O)
                  </p>
                  <p>
                    Passport, Document Fraud (imprisonment term of at least 1
                    yr) INA 101(a)(43)(P)
                  </p>
                  <p>
                    Failure to Appear Sentence (offense punishable by at least 5
                    yrs) INA 101(a)(43)(Q)
                  </p>
                  <p>
                    Bribery, Counterfeiting, Forgery, or Trafficking in Vehicles
                    INA 101(a)(43)(R)
                  </p>
                  <p>
                    Obstruction of Justice, Perjury, Bribery of Witness INA
                    101(a)(43)(S)
                  </p>
                  <p>
                    Failure to Appear to Court (offense punishable by at least 2
                    yrs) INA 101(a)(43)(T)
                  </p>
                  <p>
                    Attempt or Conspiracy to Commit an Aggravated Felony INA
                    101(a)(43)(U)
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "9")
      return (
        <Description
          title="212-I Waiver"
          description={
            <div className="post-right_body">
              <div>
                <h2>212(I) Waiver</h2>
                <br />
                <div>
                  <p>
                    You may need a fraud waiver, if you by fraud or willful
                    misrepresentation of a material fact, have sought to
                    procure, or have procured a visa, other documentation, or
                    admission into the US or other benefit under US immigration
                    laws. You may have omitted certain information on a previous
                    application for a visa or a green card. You may have failed
                    to reveal that you were married. The possibilities are so
                    many.
                  </p>
                  <p>Fraud is lifetime bar to remaining in the US.</p>
                  <p>Fraud Waivers</p>
                  <p>
                    The government may waive your fraud if your spouse or parent
                    is a US citizen or a green card holder. However, you need to
                    show that if you were not granted a fraud waiver, these
                    relatives would suffer “extreme hardship.”
                  </p>
                  <p>
                    Note that fraud waivers are more limited than section 212(h)
                    criminal waivers. Unlike 212(h) waivers, in deciding fraud
                    waivers, hardship to your children is not considered.
                  </p>
                  <p>Extreme Hardship</p>
                  <p>
                    Extreme Hardship factors include: whether your qualifying
                    relatives have family ties to the US; the extent of the
                    qualifying relatives’ family ties outside the US; conditions
                    in your home country; financial impact of your departure
                    from the US; and significant health conditions, particularly
                    when tied to an unavailability of suitable medical care in
                    your country. An 212(i) fraud waiver cannot be granted to a
                    person who falsely represents, or has falsely represented
                    himself to be a citizen of the US for any purpose or benefit
                    under the immigration law. Applying for a fraud waiver is an
                    extremely complex process.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsection === "4") {
      return (
        <div className="servicesDeportation">
          <div className="cardsDeportation">
            <div className="cardDeportation" id="1" onClick={this.onClick}>
              <Card3
                title="Asylum"
                id="1"
                image={image}
                followLink="testimonials/asylum"
              />
            </div>
            <div className="cardDeportation" id="2" onClick={this.onClick}>
              <Card3
                title="Adjustment Of Status"
                id="2"
                image={image}
                followLink="testimonials/adjustment"
              />
            </div>
            <div className="cardDeportation" id="3" onClick={this.onClick}>
              <Card3
                title="Cancellation of Removal for Illegal Aliens"
                id="3"
                image={image}
                followLink="testimonials/cancellation"
              />
            </div>
            <div className="cardDeportation" id="4" onClick={this.onClick}>
              <Card3
                title="Cancellation of Removal for Green Card Holders"
                id="4"
                image={image}
                followLink="testimonials/cancellation"
              />
            </div>
            <div className="cardDeportation" id="5" onClick={this.onClick}>
              <Card3
                title="212C Waiver"
                id="5"
                image={image}
                followLink="testimonials/212C"
              />
            </div>
            <div className="cardDeportation" id="6" onClick={this.onClick}>
              <Card3
                title="212H Waiver"
                id="6"
                image={image}
                followLink="testimonials/212H"
              />
            </div>
            <div className="cardDeportation" id="7" onClick={this.onClick}>
              <Card3
                title="Crime of Moral Turpitude"
                id="7"
                image={image}
                followLink="testimonials/moralturpitude"
              />
            </div>
            <div className="cardDeportation" id="8" onClick={this.onClick}>
              <Card3
                title="Aggravated Felony"
                id="8"
                image={image}
                followLink="testimonials/aggravatedfelony"
              />
            </div>
            <div className="cardDeportation" id="9" onClick={this.onClick}>
              <Card3
                title="212(I) Waiver"
                id="9"
                image={image}
                followLink="testimonials/212I"
              />
            </div>
          </div>
        </div>
      );
    } else if (this.props.card.cardName === "family") {
      return <div>family</div>;
    }
  }
}

Deportation.propTypes = {
  currentCard: PropTypes.func
};

const mapStatetoProps = state => ({
  auth: state.auth,
  card: state.card,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStatetoProps,
    { currentCard }
  )(Deportation)
);
