import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { currentCard } from "../actions/cardActions";

import image from "../images/woman-3602245_1920.jpg";
import "../styles/family.css";

import Card3 from "./Card3";
import Description from "./Description";

class Family extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(n) {
    if (n.target.id === "1") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "1",
          links: ["Immediate Relatives"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "2") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "2",
          links: ["Parents"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "3") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "3",
          links: ["Married Children of U.S. Citizens"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "4") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "4",
          links: ["Brothers and Sisters of U.S. Citizens"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "5") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "5",
          links: ["Unmarried Children of Green Card Holders"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "6") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "6",
          links: ["Same Sex Couples"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "7") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "7",
          links: ["Marriage"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "8") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "8",
          links: ["751 Waiver"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "9") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "9",
          links: ["Adoptions"]
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (n.target.id === "10") {
      this.payload = {
        card: {
          section: "1",
          subsection: "1",
          subsubsection: "1",
          subsubsubsection: "10",
          links: ["601A Provisional Waiver"]
        }
      };
      this.props.currentCard(this.payload.card);
    }
  }

  render() {
    if (this.props.card.subsubsubsection === "1")
      return (
        <Description
          title="Immediate Relatives"
          description={
            <div className="post-right_body">
              <div>
                <h2>Immediate Relatives</h2>
                <br />
                <div>
                  <p>
                    Immediate relatives of US citizen are permitted to get green
                    cards without any quota restrictions. Immediate relatives
                    include spouses, parents and children of US citizens. Most
                    immediate relatives are able to become permanent residents
                    within 6 to 12 months. If the marriage on which the
                    immediate relative relationship is based is less than 2
                    years old, the spouse and any children will receive 2-year
                    green cards a.k.a conditional green cards.
                  </p>
                  <p>
                    An immediate relative child must be under the age of 21 and
                    unmarried. If the citizen parent files a proper visa
                    petition before the child’s 21st birthday, the child’s age
                    is frozen at 20 years even though the child will be 21 years
                    of age or older when he actually receives his green card.
                    There are special rules for step-children and adopted
                    children. In order to sponsor an immediate relative parent,
                    the sponsoring son or daughter must be at least 21 years
                    old.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "2")
      return (
        <Description
          title="Parents"
          description={
            <div className="post-right_body">
              <div>
                <h2>Parents</h2>
                <br />
                  <div>
                    <p>
                    If you are a US citizen and 21 years old or older then you
                    are eligible to get green cards for your parents. Parents of
                    adult US citizens are considered “immediate relatives” under
                    US Immigration Law, thus, it takes much shorter time to be
                    processed, in comparison to other family based petitions.
                    There is a time difference based on if your parents are
                    lawfully in the US, unlawfully in the US, or abroad.
                  </p>
                  <p>
                    If your parents have entered the US lawfully and are
                    currently living in the US then the process takes about 6
                    months. Once they are successfully fingerprinted and have
                    had medical examinations they will receive their work and
                    travel permits within 90 days. If your parents are living
                    abroad, the process will take about one year before they
                    receive green cards.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "3")
      return (
        <Description
          title="Married Sons and Daughters of US Citizens"
          description={
            <div className="post-right_body">
              <div>
                <h2>Married Sons and Daughters of US Citizens</h2>
                <br />
                <div>
                  <p>
                    If you are a US citizen, you are eligible to sponsor your
                    married children for green cards. Their spouses and
                    children, if unmarried and under the age of 21, may also
                    come with them. Under the Child Status Protection Act, if
                    their children turn 21 during the petitioning process, they
                    can subtract the time that your visa petition was pending
                    from their ages at the time that their priority date becomes
                    current. This process can be long, because US Immigration
                    Law limit the number persons that are able to immigrate in
                    this category.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "4")
      return (
        <Description
          title="Brother and Sisters of US Citizens"
          description={
            <div className="post-right_body">
              <div>
                <h2>Brother and Sisters of US Citizens</h2>
                <br />
                <div>
                  <p>
                    If you are a US citizen and 21 or older, you can get green
                    cards for your siblings, while Legal Permanent Residents are
                    not eligible to sponsor siblings for green card. If your
                    siblings are married, their spouses and children can
                    immigrate at the same time. The children of your sibling
                    have to be unmarried and under the age of 21 at the time you
                    apply. If they turn 21 during the process, you can subtract
                    the time that your visa petition was pending from their age
                    at the time that their priority date becomes current. <br />
                    Siblings through adoption must submit a copy of the adoption
                    decrees to prove the adoption took place before either of
                    you turned 16. Siblings through a step-parent must submit
                    copies of documents that prove the legal termination of any
                    prior marriage of the natural parents and step-parent. The
                    process of sponsoring siblings for US green cards can be as
                    lengthy as 12-13 years.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "5")
      return (
        <Description
          title="Unmarried Sons and Daughters of US Citizens"
          description={
            <div className="post-right_body">
              <div>
                <h2>Unmarried Sons and Daughters of US Citizens</h2>
                <br />
                <div>
                  <p>
                    US citizen parents may petition for green cards for their
                    unmarried sons and daughters. Also, their grandchildren may
                    immigrate come together with their parent as long as they
                    are under the age of 21 and unmarried. If your son or
                    daughter is 21 years of age or older when you petition them
                    for green cards, the process can be long, because US
                    immigration laws put a limit on the number of persons that
                    can immigrate under the 1st preference category (unmarried
                    adult sons/daughters of US citizens).
                  </p>
                  <p>
                    After receiving the petition package, the USCIS will review
                    your petition. First, USCIS will make decision to approve
                    the petition. Then, much later when the priority date is
                    reached, your unmarried son or daughter and their children
                    will be interviewed. If the interview was successful, they
                    will become legal permanent residents (LPR) of the US.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "6")
      return (
        <Description
          title="Same Sex Couples"
          description={
            <div className="post-right_body">
              <div>
                <h2>Same Sex Couples</h2>
                <br />
                <div>
                  <p>
                    Under a recent US Supreme Court case law, the federal court
                    is blocked from denying same-sex couples any benefits that
                    different-sex couples receive. This means that US citizens
                    and legal permanent residents can sponsor their same-sex
                    partners for green cards and that same-sex spouses can also
                    be included as derivatives in their partners’ family and
                    employment based applications. <br />
                    As long as a same-sex couple is married in a state that
                    permits gay marriage, their marriage is valid for
                    immigration law purposes and the couple may live in
                    whichever state they elect to live, because immigration is a
                    federal law. Immigrants in same-sex relationship who live
                    outside of the U.S., also benefit from this new law.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "7")
      return (
        <Description
          title="Marriage"
          description={
            <div className="post-right_body">
              <div>
                <h2>Marriage</h2>
                <br />
                <div>
                  <p>
                    The spouses of U.S. citizens are considered an “immediate
                    relative” under US immigration law. That means that there
                    are no quota restrictions on the number of people who can
                    obtain green cards through marriage to U.S. citizens. If the
                    spouses entered the U.S. lawfully, they can file for
                    adjustment of status without having to leave the U.S.
                    Normally, the spouse receives an EAD( work permit) within 90
                    days. Also, they get an Advance Parole document to travel
                    abroad, provided the immigration attorney applied one for
                    them. If the foreign-born spouses entered the U.S. without
                    inspection, they need to apply for a green card through
                    marriage in their home country. However, they may be
                    eligible to apply for a provisional waiver in the United
                    States, depending on surrounding circumstances.
                    <br />
                    If the marriage is less than two years old when the green
                    card is granted, the couple will have a two-year or
                    conditional green card. The couple need to jointly petition
                    to remove the condition, 90 days before the expiration of
                    the green card. If the couple divorces before the end of the
                    two-year period, the foreign-born spouse my petition for a
                    “good faith marriage waiver” of the joint petition
                    requirement.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "8")
      return (
        <Description
          title="751 Waiver"
          description={
            <div className="post-right_body">
              <div>
                <h2>751 Waiver</h2>
                <br />
                <div>
                  <p>
                    Individuals who are married U.S. citizens may apply for a
                    green card, in order to remain in the U.S. with their
                    spouse. In order to become a permanent resident, they must
                    first file for a 2 years conditional green cards and then
                    they are required to apply to remove the condition and
                    obtain a 10-year green card. If your marriage ends in a
                    divorce before your conditional permanent residence (CPR)
                    expires, turn to us and get help! Immigration examiners in
                    the USCIS conduct thorough interview, concerning the
                    validity of your marriage, at the end of the processing the
                    petition.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "9")
      return (
        <Description
          title="Adoptions"
          description={
            <div className="post-right_body">
              <div>
                <h2>Adoptions</h2>
                <br />
                <div>
                  <p>
                    U.S. citizens who wish to adopt a child from a foreign
                    country need to know how to find and adopt a child, how the
                    legal process works in the foreign country, and how to
                    obtain a green card and citizenship for the child. When
                    trying to learn more about green cards through adoption, a
                    good place to start is at the government’s adoption page on
                    the State Department website. <br />
                    The State Department (DOS) has established a special website
                    in which they answer frequently asked questions about the
                    role of the U.S. government; provide general guidelines;
                    explain the foreign adoption process; detail the
                    naturalization and US Immigration Law requirements; and
                    provide links to useful public and private organizations.
                    <br />
                    We can effectively help and guide our clients who wants to
                    adopt a child which is extremely difficult and time
                    consuming process. In this process, many thing could go
                    wrong, thus, we advise our clients to retain us first,
                    before they start doing anything on their own.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    if (this.props.card.subsubsubsection === "10")
      return (
        <Description
          title="A601 Waiver"
          description={
            <div className="post-right_body">
              <div>
                <h2>601A Provisional Waiver</h2>
                <br />
                <div>
                  <p>
                    In 2013, the Department of Homeland Security published a
                    federal regulation that allows immediate family members of
                    U.S. citizens who entered the U.S. without inspection, or
                    are otherwise ineligible to adjust their status in the U.S.
                    due to overstay, to apply for “provisional waivers” in the
                    United States.
                    <br />
                    Once their waivers are approved by the USCIS, they will be
                    eligible to attend their appointments for immigrant visas in
                    their countries of origin. The purpose of this program,
                    which became effective on March 4, 2013, is to avoid having
                    spouses and sons and daughters of US citizens be separated
                    from their families for months or even years while their
                    waivers are pending. Now, if these family members get their
                    waivers, before leaving the U.S., then they will be
                    interviewed abroad. Then they reunite with their families in
                    the U.S. within just a few weeks.
                  </p>
                </div>
              </div>
            </div>
          }
        />
      );
    else if (this.props.card.subsubsection === "1") {
      return (
        <div className="servicesFamily">
          <div className="cardsFamily">
            <div className="cardFamily" id="1" onClick={this.onClick}>
              <Card3 title="Immediate Relatives" id="1" image={image} followLink = "testimonials/immediaterelatives"/>
            </div>
            <div className="cardFamily" id="2" onClick={this.onClick}>
              <Card3 title="Parents" id="2" image={image} followLink = "testimonials/parents"/>
            </div>
            <div className="cardFamily" id="3" onClick={this.onClick}>
              <Card3
                title="Married Children of US Citizens"
                id="3"
                image={image}
                followLink = "testimonials/marriedchildren"
              />
            </div>
            <div className="cardFamily" id="4" onClick={this.onClick}>
              <Card3
                title="Brother and Sisters of US Citizens"
                id="4"
                image={image}
                followLink = "testimonials/brothersandsisters"
              />
            </div>
            <div className="cardFamily" id="5" onClick={this.onClick}>
              <Card3
                title="Unmarried Children of Green Card Holders"
                id="5"
                image={image}
                followLink = "testimonials/unmarriedchildren"
              />
            </div>
            <div className="cardFamily" id="6" onClick={this.onClick}>
              <Card3 title="Same Sex Couples" id="6" image={image} followLink = "testimonials/samesex"/>
            </div>
            <div className="cardFamily" id="7" onClick={this.onClick}>
              <Card3 title="Marriage" id="7" image={image} followLink = "testimonials/marriage"/>
            </div>
            <div className="cardFamily" id="8" onClick={this.onClick}>
              <Card3 title="751 Waiver" id="8" image={image} followLink = "testimonials/751"/>
            </div>
            <div className="cardFamily" id="9" onClick={this.onClick}>
              <Card3 title="Adoptions" id="9" image={image} followLink = "testimonials/adoptions"/>
            </div>
            <div className="cardFamily" id="10" onClick={this.onClick}>
              <Card3 title="601A Provisional Waiver" id="10" image={image} followLink = "testimonials/601a"/>
            </div>
          </div>
        </div>
      );
    } else if (this.props.card.cardName === "family") {
      return <div>family</div>;
    }
  }
}

Family.propTypes = {
  currentCard: PropTypes.func
};

const mapStatetoProps = state => ({
  auth: state.auth,
  card: state.card,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStatetoProps,
    { currentCard }
  )(Family)
);
