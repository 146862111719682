import React, { Component } from "react";
import "../styles/practiceareas.css";
import scale from "../images/iconfinder_70_2529982.svg";

import Fade from "react-reveal/Fade";

export default class PracticeAreas extends Component {
  render() {
    return (
      <div className="practiceareas">
        <div className="headerPane">
          <p className="headerTxt">PRACTICE AREAS</p>
          <p className="subTextpa">In Illinois</p>
        </div>
        <div className="areaPane">
          <ul className="areaList">
            <div className="areaItems">
              <img src={scale} className="gavel" alt="gavel" />
              <span className="listItems">Immigration Law </span>
              <p className="practiceAreaDesc">
                <svg
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                  className="bulletPoint"
                >
                  <path d="M7 4 L12 9 7 14 6 13 10 9 6 5 Z" />
                </svg>
                U.S. Immigration Law is tremendously complex area of law to practice for attorneys. Therefore, hiring and working with properly trained and adequately experienced immigration attorney is absolutely vital for people who need immigration legal services. We are a full service Immigration Law firm. Thus, we provide family based and employment based all immigration petitions, applications, and waivers services. Further, we provide professional legal services in both affirmative asylum petitions in U.S. asylum office and defensive asylum petitions in Immigration Courts.  
              </p>
            </div>
            <div className="areaItems">
              <img src={scale} className="gavel" alt="gavel" />
              <span className="listItems">Family Law </span>
              <p className="practiceAreaDesc">
                <svg
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                  className="bulletPoint"
                >
                  <path d="M7 4 L12 9 7 14 6 13 10 9 6 5 Z" />
                </svg>
                We provide legal services people who need child custody and dissolution of marriage cases. We strive for cost effective and and quality legal services for our clients. No matter where the marriage was registered and no matter your current spouse is located, you are eligible to start divorce proceedings in Illinois, as long as you have resided in state of Illinois for last 90 days.
              </p>
            </div>
            <div className="areaItems">
              <img src={scale} className="gavel" alt="gavel" />
              <span className="listItems">Criminal Defense </span>
              <p className="practiceAreaDesc">
                <svg
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                  className="bulletPoint"
                >
                  <path d="M7 4 L12 9 7 14 6 13 10 9 6 5 Z" />
                </svg>
                We provide criminal defense legal services in Cook, Du Page, Lake, Will, and Kane County Courts. Having a criminal record could affect your employment, your future education, and your immigration status change.
              </p>
            </div>
            <div className="areaItems">
              <img src={scale} className="gavel" alt="gavel" />
              <span className="listItems">DUI/Traffic </span>
              <p className="practiceAreaDesc">
                <svg
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                  className="bulletPoint"
                >
                  <path d="M7 4 L12 9 7 14 6 13 10 9 6 5 Z" />
                </svg>
                Being charged with DUI or serious traffic violations tickets could get stressful for our clients, specially for individuals who earn living by driving trucks or taxi cabs. We have successfully litigated and won DUI trials in local courts for our clients over the years.
              </p>
            </div>
            <div className="areaItems">
              <img src={scale} className="gavel" alt="gavel" />
              <span className="listItems">Real Estate </span>
              <p className="practiceAreaDesc">
                <svg
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                  className="bulletPoint"
                >
                  <path d="M7 4 L12 9 7 14 6 13 10 9 6 5 Z" />
                </svg>                
                We represent our clients in real estate closings by providing competent legal services for both buyer's and seller's side of the real estate transactions. We have developed a solid professional networks among fellow real estate attorneys, mortgage brokers, and realtors in greater Chicago area to make real estate transactions to be as smooth as possible for our clients.
              </p>
            </div>
          </ul>
        </div>
      </div>
    );
  }
}
