import React, { Component } from "react";
import {
  CollapsibleComponent,
  CollapsibleHead,
  CollapsibleContent
} from "react-collapsible-component";

import { db } from "./Firebase";

import "../styles/firebaseCards.scss";

class FirebaseCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Testimonial: this.props.Testimonial.TestimonialText,
      TestimonialList: [],
      loading: true,
      expanded: false,
      Category: this.props.Testimonial.Category
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateTestimonial = this.updateTestimonial.bind(this);
    this.deleteTestimonial = this.deleteTestimonial.bind(this);
    this.updateTestimonialActive = this.updateTestimonialActive.bind(this);
    this.expand = this.expand.bind(this);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  updateTestimonial() {
    db.ref()
      .child("/Testimonials/" + this.props.Testimonial.id)
      .update({
        TestimonialText: this.state.Testimonial,
        Category: this.state.Category
      });
    this.forceUpdate();
  }
  updateTestimonialActive() {
    var status = this.props.Testimonial.Active;
    db.ref()
      .child("/Testimonials/" + this.props.Testimonial.id)
      .update({
        Active: !status
      });
    this.props.Testimonial.Active = !status;
  }
  deleteTestimonial() {
    db.ref()
      .child("/Testimonials/" + this.props.Testimonial.id)
      .remove();
    this.setState(this.state);
  }
  expand() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    var checked = this.props.Testimonial.Active ? "checked" : null;
    console.log(this.props.Category);
    return (
      <div className="fbCard">
        <CollapsibleComponent onClick={this.expand}>
          <div onClick={this.expand}>
            <CollapsibleHead
              isExpanded={this.state.expanded}
              className="additionalClassForHead"
            >
              Testimonial Added: {this.props.Testimonial.date}
            </CollapsibleHead>
          </div>
          <CollapsibleContent
            isExpanded={this.state.expanded}
            className="additionalClassForContent"
          >
            <div className="updateBox">
              <textarea
                type="text"
                placeholder="Add Legal News..."
                value={this.state.Testimonial}
                onChange={this.handleChange}
                name="Testimonial"
                className="dropdownInput"
              />
              <div className="testimonialButtons">
                <button
                  onClick={this.updateTestimonial}
                  className="testimonialButtons__submitButton"
                >
                  UPDATE
                </button>
                <button
                  onClick={this.deleteTestimonial}
                  className="testimonialButtons__submitButton"
                >
                  Delete
                </button>
                <div className="checkboxGroup">
                  <input
                    onClick={this.updateTestimonialActive}
                    className="testimonialButtons__checkBox"
                    type="checkbox"
                    checked={checked}
                  />
                  {this.props.Testimonial.Active ? (
                    <strong>Displayed on Front page</strong>
                  ) : (
                    <strong>Not being Displayed on Front Page</strong>
                  )}
                  <select
                    className="legalNewsDropdown"
                    onChange={this.handleChange}
                    name="Category"
                    value={this.state.Category}
                  >
                    <option value="dui" name="Category">
                      DUI
                    </option>
                    <option value="criminallaw" name="Category">
                      Criminal Law
                    </option>
                    <option value="divorce" name="Category">
                      Divorce
                    </option>
                    <option value="realestate" name="Category">
                      Real Estate
                    </option>
                    <option value="citizenship" name="Category">
                      Citizenship
                    </option>
                    <option value="investor" name="Category">
                      Investor
                    </option>
                    <option value="asylum" name="Category">
                      Asylum
                    </option>
                    <option value="uvisa" name="Category">
                      U Visa
                    </option>
                    <option value="tvisa" name="Category">
                      T Visa
                    </option>
                    <option value="immediaterelatives" name="Category">
                      Immediate Relatives
                    </option>
                    <option value="parent" name="Category">
                      Parents
                    </option>
                    <option value="marriedchildren" name="Category">
                      Married Children
                    </option>
                    <option value="brothersandsisters" name="Category">
                      Brothers and Sisters
                    </option>
                    <option value="unmarriedchildren" name="Category">
                      Unmarried Children
                    </option>
                    <option value="samesex" name="Category">
                      Same Sex Couples
                    </option>
                    <option value="marriage" name="Category">
                      Marriage
                    </option>
                    <option value="751" name="Category">
                      751
                    </option>
                    <option value="adoptions" name="Category">
                      Adoptions
                    </option>
                    <option value="601a" name="Category">
                      601a
                    </option>
                    <option
                      value="Persons of Extraordinary Ability"
                      name="Category"
                    >
                      Persons of Extraordinary Ability
                    </option>
                    <option value="professors" name="Category">
                      Professors
                    </option>
                    <option value="nationalinterest" name="Category">
                      National Interest
                    </option>
                    <option value="religousworkers" name="Category">
                      Religous Workers
                    </option>
                    <option value="perm" name="Category">
                      Perm
                    </option>
                    <option value="E2" name="Category">
                      E2
                    </option>
                    <option value="H1B" name="Category">
                      H1B
                    </option>
                    <option value="J1" name="Category">
                      J1
                    </option>
                    <option value="Extension" name="Category">
                      Extension
                    </option>
                    <option value="ChangeofStatus" name="Category">
                      Change of Status
                    </option>
                    <option value="F1Reinstatement" name="Category">
                      F1 Reinstatement
                    </option>
                    <option value="Miscellaneous" name="Category">
                      Miscellaneous
                    </option>
                    <option value="adjustment" name="Category">
                      Adjustment
                    </option>
                    <option value="cancellation" name="Category">
                      Cancellation
                    </option>
                    <option value="212C" name="Category">
                      212C
                    </option>
                    <option value="212H" name="Category">
                      212H
                    </option>
                    <option value="moralturpitude" name="Category">
                      Moral Turpitude
                    </option>
                    <option value="aggravatedfelony" name="Category">
                      Aggravated Felony
                    </option>
                    <option value="212I" name="Category">
                      212I
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </CollapsibleContent>
        </CollapsibleComponent>
      </div>
    );
  }
}

export default FirebaseCards;
