import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { currentCard } from "../actions/cardActions";

import Asylum from "./Asylum";
import Description from "./Description";
import Card2 from "./Card2";
import Navigation from "./Navigation";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";

import image from "../images/silhouette-1082129_1920.jpg";
import "../styles/services.css";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: {
        section: "main"
      },
      links: ["Home"]
    };

    this.onClick = this.onClick.bind(this);
    this.back = this.back.bind(this);

    console.log("Service is rendered in constructor");
  }

  componentWillUnmount() {
    this.payload = {
      card: {
        section: "main",
        remove: this.props.card.links.slice(0, 1)
      }
    };
    this.props.currentCard(this.payload.card);
  }

  onClick(n) {
    if (n.target.id === "1") {
      this.payload = {
        card: {
          section: "1",
          links: ["Immigration Law"]
        }
      };
      this.props.currentCard(this.payload.card);
    }
  }

  back() {
    if (this.props.card.subsubsubsection) {
      this.payload = {
        card: {
          section: this.props.card.section,
          subsection: this.props.card.subsection,
          subsubsection: this.props.card.subsubsection,
          subsubsubsection: ""
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (this.props.card.subsubsection) {
      this.payload = {
        card: {
          section: this.props.card.section,
          subsection: this.props.card.subsection,
          subsubsection: ""
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (this.props.card.subsection) {
      this.payload = {
        card: {
          section: this.props.card.section,
          subsection: ""
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (this.props.card.section) {
      if (this.props.card.section !== "main") {
        this.payload = {
          card: {
            section: "main"
          }
        };
        this.props.currentCard(this.payload.card);
      } else {
        console.log("end");
      }
    }
  }

  renderElement() {
    window.scrollTo(0, 0);
    if (this.props.card.section === "main")
      return (
        <React.Fragment>
          <div className="servicesCard" id="1" onClick={this.onClick}>
            <Card2 title="IMMIGRATION LAW" id="1" image={image} />
          </div>
        </React.Fragment>
      );
    else if (this.props.card.section === "1") return <Asylum />;
  }

  renderButton() {
    if (this.props.card.section !== "main")
      return (
        <div className="backArrow" onClick={this.back}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-8 mr-4 icon-arrow-thin-left-circle"
          >
            <circle cx="12" cy="12" r="10" class="primary" />
            <path
              class="secondary"
              d="M9.41 11H17a1 1 0 0 1 0 2H9.41l2.3 2.3a1 1 0 1 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.42 1.4L9.4 11z"
            />
          </svg>
        </div>
      );
    else
      return (
        <div className="backArrowHidden" onClick={this.back}>
          <p />
        </div>
      );
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="servicesMain">
          {/* <ul className="navigation">{this.listItems}</ul> */}
          <Navigation entries={this.props.card.links} />
          {this.renderElement()}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

Services.propTypes = {
  currentCard: PropTypes.func
};

const mapStatetoProps = state => ({
  auth: state.auth,
  card: state.card,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStatetoProps,
    { currentCard }
  )(Services)
);
