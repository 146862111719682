import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { currentCard } from "../actions/cardActions";

import "../styles/navigation.css";

class Navigation extends Component {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
    this.createLists = this.createLists.bind(this);
    this.filterArray = this.filterArray.bind(this);
  }

  filterArray(value) {
    return (value = value);
  }

  onClick(m) {
    var linkLocation = this.props.card.links.findIndex(link => {
      return link === m;
    });

    if (linkLocation === 0) {
      this.payload = {
        card: {
          section: "main",
          remove: this.props.card.links.slice(0, linkLocation + 1)
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (linkLocation === 1) {
      this.payload = {
        card: {
          section: this.props.card.section,
          remove: this.props.card.links.slice(0, linkLocation + 1)
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (linkLocation === 2) {
      this.payload = {
        card: {
          section: "1",
          subsection: this.props.card.subsection,
          remove: this.props.card.links.slice(0, linkLocation + 1)
        }
      };
      this.props.currentCard(this.payload.card);
    } else if (linkLocation === 3) {
      this.payload = {
        card: {
          section: "1",
          subsection: this.props.card.subsection,
          subsubsection: this.props.card.subsubsection,
          remove: this.props.card.links.slice(0, linkLocation + 1)
        }
      };
      this.props.currentCard(this.payload.card);
    }
  }

  createLists(links) {
    return (
      <li key={links.key} className="links" onClick={() => this.onClick(links)}>
        {links}
      </li>
    );
  }

  render() {
    var navLinks = this.props.entries;
    var linkList = navLinks.map(this.createLists);

    return <ul className="navigation">{linkList}</ul>;
  }
}

Navigation.propTypes = {
  currentCard: PropTypes.func
};

const mapStatetoProps = state => ({
  auth: state.auth,
  card: state.card,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStatetoProps,
    { currentCard }
  )(Navigation)
);
