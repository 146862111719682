import firebase from "firebase";

const config = {
  apiKey: "AIzaSyAPB4fg1TjcsePTPWIrun6xP7KDOHjPUO4",
  authDomain: "tsegtslaw-5bda2.firebaseapp.com",
  databaseURL: "https://tsegtslaw-5bda2.firebaseio.com",
  projectId: "tsegtslaw-5bda2",
  storageBucket: "tsegtslaw-5bda2.appspot.com",
  messagingSenderId: "62570901367"
};

firebase.initializeApp(config);
export const auth = firebase.auth();
export const db = firebase.database();
export default firebase;
